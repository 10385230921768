import { Component, OnInit, Injectable } from '@angular/core';

import { AuthService } from '../../core/auth.service';
import { AngularFireAuth } from 'angularfire2/auth';

// get our interfaces
import {  User, Roles } from '../../interfaces';

// import the database stuff
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from 'angularfire2/firestore';

import { FirestoreService } from '../../firestore.service';

// need observables
import { Observable } from 'rxjs/Observable';

@Component({
  selector: 'app-calculator-dashboard',
  templateUrl: './calculator-dashboard.component.html',
  styleUrls: ['./calculator-dashboard.component.scss']
})

// @Injectable()

export class CalculatorDashboardComponent implements OnInit {
  // authState: any = null;

  constructor(
    public auth: AuthService,
    public afAuth: AngularFireAuth,
    public afs: AngularFirestore,
    public db: FirestoreService) {}

  ngOnInit() {
  }

}
