import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../core/auth.service';
import {
  ReactiveFormsModule,
  FormGroup,
  FormBuilder,
  Validators
} from '@angular/forms';

// get our interfaces
import { Rates } from '../../interfaces';

// import the database stuff
import {
  AngularFirestore,
  AngularFirestoreDocument,
  AngularFirestoreCollection
  } from 'angularfire2/firestore';

@Component({
  selector: 'app-update-rates',
  templateUrl: './update-rates.component.html',
  styleUrls: ['./update-rates.component.scss']
})
export class UpdateRatesComponent implements OnInit {

  curPrime: number;
  curLibor: number;
  cur10Ycmt: number;
  curRadioRate: string;
  curMortEClose: number;
  curHomeEClose: number;

  ratesCollectionRef: AngularFirestoreCollection<Rates>;

  constructor(
    private auth: AuthService,
    private fb: FormBuilder,
    private afs: AngularFirestore
    ) {
    this.ratesCollectionRef = this.afs.collection<Rates>('Rates');
  }

  updateRatesForm: FormGroup;

  formErrors = {
    'prime': '',
    'libor': '',
    'tenYcmt': ''
  };

  validationMessages = {
    'prime': {
      'required': 'Please only use numbers'
    },
    'libor': {
      'required': 'Please only use numbers'
    },
    'tenYcmt': {
      'required': 'Please only use numbers',
    },
    'mortEClose': {
      'required': 'Please only use numbers',
    },
    'homeEClose': {
      'required': 'Please only use numbers',
    }
  };

  ngOnInit() {
    this.buildUpdateRatesForm();
    this.getRates();
  }

  buildUpdateRatesForm(): void {
    this.updateRatesForm = this.fb.group({
      'prime': [this.curPrime, [
        Validators.pattern('\\d*\\.?\\d*')

        ]
      ],
      'libor': [this.curLibor, [
        Validators.pattern('\\d*\\.?\\d*')
        ]
      ],
      'tenYcmt': [this.cur10Ycmt, [
        Validators.pattern('\\d*\\.?\\d*')
        ]
      ],
      'radioRate': [this.curRadioRate, []
      ],
      'mortEClose': [this.curMortEClose, [
        Validators.pattern('\\d*\\.?\\d*')
        ]
      ],
      'homeEClose': [this.curHomeEClose, [
        Validators.pattern('\\d*\\.?\\d*')
        ]
      ],
    });
  }



  private updateNewRate() {
    const newPrime = this.updateRatesForm.value['prime'];
    const newLibor = this.updateRatesForm.value['libor'];
    const new10Ycmt = this.updateRatesForm.value['tenYcmt'];
    const newRadioRate = this.updateRatesForm.value['radioRate'];
    const newMortEClose = this.updateRatesForm.value['mortEClose'];
    const newHomeEClose = this.updateRatesForm.value['homeEClose'];

    const ratesData: Rates = {
      prime: newPrime,
      libor: newLibor,
      tenYcmt: new10Ycmt,
      radioRate: newRadioRate,
      mortEClose: newMortEClose,
      homeEClose: newHomeEClose

    }

    this.ratesCollectionRef.doc('allRates').set(ratesData)
      .then( () => {
        console.log('set ratesData is successful');
        this.getRates();
      })
      .catch( error => {
        console.log('error on sending ratesData');
        console.log(error);
      });

  // end of updateNewRate
  }

  private updateNewEClose() {
    console.log('other form');
  }

  private getRates() {
    const self = this;
    this.ratesCollectionRef.doc('allRates').ref.get().then(function(doc) {
      if (doc.exists) {
        const currentData = doc.data();
        self.curPrime = currentData['prime'];
        self.curLibor = currentData['libor'];
        self.cur10Ycmt = currentData['tenYcmt'];
        self.curRadioRate = currentData['radioRate'];
        self.curMortEClose = currentData['mortEClose'];
        self.curHomeEClose = currentData['homeEClose'];
        self.updateRatesForm.controls['prime'].setValue(self.curPrime);
        self.updateRatesForm.controls['libor'].setValue(self.curLibor);
        self.updateRatesForm.controls['tenYcmt'].setValue(self.cur10Ycmt);
        self.updateRatesForm.controls['radioRate'].setValue(self.curRadioRate);
        self.updateRatesForm.controls['mortEClose'].setValue(self.curMortEClose);
        self.updateRatesForm.controls['homeEClose'].setValue(self.curHomeEClose);
      }
    });
  }

}
