import {Component, OnInit, OnDestroy, Injectable} from '@angular/core';

import { AuthService } from '../../core/auth.service';
import { AngularFireAuth } from 'angularfire2/auth';

import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from 'angularfire2/firestore';

import { FirestoreService } from '../../firestore.service';

import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';

import {User, SendEmail} from '../../interfaces';

import { saveAs } from 'file-saver';

import * as firebase from 'firebase';
const objectData = [];


@Component({
  selector: 'user-dashboard',
  templateUrl: './user-dashboard.component.html',
  styleUrls: ['./user-dashboard.component.scss']
})

@Injectable()


export class UserDashboardComponent implements OnInit, OnDestroy {
  reportsRef: AngularFirestoreCollection<any>;
  reports: Observable<any>;
  curEmail: string;
  userCollectionRef: AngularFirestoreCollection<User>;
  sendEmailsCollectionRef: AngularFirestoreCollection<SendEmail>;



  constructor(
    public auth: AuthService,
    public afAuth: AngularFireAuth,
    public afs: AngularFirestore,
    public db: FirestoreService) {
      this.sendEmailsCollectionRef = this.afs.collection<SendEmail>('SendEmails');
    }


  ngOnInit() {

    // get the user role from the db
    // getting this in the auth service instead
    // this.userProfile = this.db.object('users/' + this.authState.uid + '/role', { preserveSnapshot: true });

    // get the value of that role
    // this.userProfile.subscribe(role => {
    // this.userRole = role.val();
    // });
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('dashboard-page');

    // this.companies = this.db.col$('companies');

    // this.getObjectArray();
    this.reportsRef = this.afs.collection('SendEmails', ref => ref.orderBy('createdAt', 'desc'));

    // Map the snapshot to include the document ID
    this.reports = this.reportsRef
      .snapshotChanges().map(arr => {
      return arr.map(snap => {
      const data = snap.payload.doc.data();
      const id = snap.payload.doc.id;
      const timestamp: any = data.createdAt;
      const date = new Date(timestamp).toLocaleDateString(undefined, {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric'
      });
      data.createdAt = date;
      objectData.push(data);
      return { ...data, id };
      });
    });
  }

  requestReport() {
    const data = {
      status: 'processing',
      createdAt: new Date()
    };
    this.reportsRef.add(data);
  }


  downloadCSV(data: any) {
    const replacer = (key: any, value: any) => value === null ? '' : value; // specify how you want to handle null values here
    const header = Object.keys(objectData[0]);
    const csv = objectData.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
    csv.unshift(header.join(','));
    const csvArray = csv.join('\r\n');
    const blob = new Blob([csvArray], {type: 'text/csv' });
    saveAs(blob, 'calculatorReport.csv');
  }


  ngOnDestroy() {
    // since we subscribe earlier, we need to unsubscribe
    // only if the variable was any = null
    // since we used an observable we do not need to unsubscribe
    // this.userProfile.unsubscribe();
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('dashboard-page');
  }

  logout() {
    this.auth.signOut();
  }


}
