import { Component, OnInit } from '@angular/core';
import { AuthService } from "../../core/auth.service";
import {
  ReactiveFormsModule,
  FormGroup,
  FormBuilder,
  Validators
} from '@angular/forms';

// get our interfaces
import {
  User,
  inviteCodes
} from '../../interfaces';

// import the database stuff
import {
	AngularFirestore,
	AngularFirestoreCollection
} from 'angularfire2/firestore'

import swal from 'sweetalert2';

@Component({
  selector: 'create-user-form',
  templateUrl: './create-user-form.component.html',
  styleUrls: ['./create-user-form.component.scss']
})
export class CreateUserFormComponent implements OnInit {

  usersCollectionRef: AngularFirestoreCollection<User>;
  inviteCodesCollectionRef: AngularFirestoreCollection<inviteCodes>;

  formErrors = {
    'firstName': '',
    'lastName': '',
    'email': ''
  };

  validationMessages = {
    'firstName': {
      'required': 'Please add a first name'
    },
    'lastName': {
      'required': 'Please add a last name'
    },
    'email': {
      'required': 'Please add an email address',
      'email': 'Please enter a valid email address'
    }
  };

  constructor(
    private auth: AuthService,
    private fb: FormBuilder,
    private afs: AngularFirestore
  ) {
      this.usersCollectionRef = this.afs.collection<User>('Users');
      this.inviteCodesCollectionRef = this.afs.collection<inviteCodes>('inviteCodes');
   }

   createUserForm: FormGroup;

  ngOnInit() {

    this.buildUserCreateForm();
  }

  buildUserCreateForm(): void {
    this.createUserForm = this.fb.group({
      'firstName': ['', [
        Validators.required,
        Validators.pattern('^[A-Za-z]{2,}$')

        ]
      ],
      'lastName': ['', [
        Validators.required,
        Validators.pattern('^[A-Za-z]{2,}$')

        ]
      ],
      'email': ['', [
        Validators.required,
        Validators.email
        ]
      ],
    });

    this.createUserForm.valueChanges.subscribe(data => this.onValueChanged(data));
    this.onValueChanged(); // reset validation messages

  }

  onValueChanged(data?: any) {
    if (!this.createUserForm) { return; }
    const form = this.createUserForm;
    for (const field in this.formErrors) {
      if (this.formErrors.hasOwnProperty(field)) {
        // clear previous error message (if any)
        this.formErrors[field] = '';
        const control = form.get(field);
        if (control && control.dirty && !control.valid) {
          const messages = this.validationMessages[field];
          for (const key in control.errors) {
            if (control.errors.hasOwnProperty(field)) {
              this.formErrors[field] += messages[key] + ' ';
            }
          }
        }
      }
    }
  }



  private generateCode(length: any) {
    const chars = 'abcdefghijklmnopqrstuvwxyz_ABCDEFGHIJKLMNOP1234567890';
    let pass = '';
    for (let x = 0; x < length; x++) {
        const i = Math.floor(Math.random() * chars.length);
        pass += chars.charAt(i);
    }
    return pass;
  }

  get timestamp() {
    const today: number = Date.now();
    return today;
  }

  private addNewUser() {

    // add the spinner here
    document.querySelector('.create--spinner .icon .fas').classList.add('fa-spinner');
    document.querySelector('.create--spinner .icon .fas').classList.add('fa-spin');

    const generatedInvite = this.generateCode(8);

    const userEmail = this.createUserForm.value['email'];
    const userFirstName  = this.createUserForm.value['firstName'].replace(/^\w/, c => c.toUpperCase());
    const userLastName = this.createUserForm.value['lastName'].replace(/^\w/, c => c.toUpperCase());

    const userData: User = {
      uid: 'not authenticated yet',
      email: userEmail,
      firstName: userFirstName,
      lastName: userLastName,
      displayName: userFirstName + ' ' + userLastName,
      roles: {
        subscriber: true,
        editor: true,
        admin: false,
        superAdmin: false,
      },
      authenticated: false,
      inviteCode: generatedInvite,
      userPhone: '',
      userSince: this.timestamp
    };

    // create inviteCodes in INVITECODES collection
    const codeData: inviteCodes = {
      tempInviteCode: generatedInvite,
      notAuthUser: userEmail,
      createdAt: this.timestamp
    };

    this.usersCollectionRef.doc(`${userEmail}`).set(userData)
      .then(() => {
        console.log('set userData is successful');
        return this.inviteCodesCollectionRef.doc(`${generatedInvite}`).set(codeData)
          .then( () => {
            console.log('invite Codes Collection updated');
            // this.auth.sendRegistrationLink(userEmail, generatedInvite);
            return;
          })
          .catch( error =>{
            console.log('invite code error');
            console.log(error)
          });
      })
      .then(() => {
        console.log('sending user registration link');
          return this.auth.sendRegistrationLink(userEmail, generatedInvite)
          .then( () => {
            console.log('the registration went through')
            // remove the spinner code
            document.querySelector('.create--spinner .icon .fas').classList.remove('fa-spinner');
            document.querySelector('.create--spinner .icon .fas').classList.remove('fa-spin');

            // set the alert
            swal({
              title: 'Success!',
              text: 'Your invitation to ' + userEmail + ' has been sent',
              type: 'success',
              confirmButtonText: 'OK',
              confirmButtonColor: '#85dce6',
              confirmButtonClass: 'button is-primary is-uppercase',
              buttonsStyling: false
            });
          })
          .catch( () => {
            console.log('there was an error with the registration link');
            swal({
              title: 'Something Went Wrong!',
              text: 'Your invitation to ' + userEmail + ' has not been sent. There was an error. Please try again.',
              type: 'error',
              confirmButtonText: 'Try Again',
              confirmButtonColor: '#85dce6',
              confirmButtonClass: 'button is-primary is-uppercase',
              buttonsStyling: false
            });
          });

      })
      .catch( error => {
        console.log('error on sending userData');
        console.log(error);
      });

  // end of addNewUser
  }


  // end of export class
}
