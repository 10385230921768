import { Component, OnInit } from '@angular/core';
import { DataService } from '../../shared/data.service';

import { Observable, of } from 'rxjs';
import { switchMap, tap, startWith, map } from 'rxjs/operators';

import {Chart} from 'chart.js';
import * as html2canvas from 'html2canvas';
import { saveAs } from 'file-saver';

// import the database stuff
import {
  AngularFirestore,
  AngularFirestoreDocument,
  AngularFirestoreCollection
  } from 'angularfire2/firestore';

import * as firebase from 'firebase';

import { AuthService } from '../../../core/auth.service'
import { AngularFireAuth } from 'angularfire2/auth';

import { FirestoreService } from '../../../firestore.service';

import {
  SendEmail,
  User,
  Rates
} from '../../../interfaces';

@Component({
  selector: 'app-home-equity-results',
  templateUrl: './home-equity-results.component.html',
  styleUrls: ['./home-equity-results.component.scss']
})
export class HomeEquityResultsComponent implements OnInit {

  user: User;

  eCloseType: boolean;
  hybridFee: number;
  base64Name: string;
  uploadReference: string;

  // numbers
  c12: number;
  c13: number;
  c23: number;
  c24: number;
  c25: number;
  c26: number;
  c28: number;
  c29: number;
  c30: number;
  c32: number;
  c32e: number;
  c33: number;
  c36: number;
  c37: number;

  d23: number;
  d24: number;
  d25: number;
  d26: number;
  d28: number;
  d29: number;
  d30: number;
  d31: number;
  d33: number;
  d36: number;
  d37: number;

  // results
  resultPaperPreClosing: number = 0;
  resultPaperPostClosing: number = 0;
  resultPaperEstCureCosts: number = 0;
  resultEClosePreClosing: number = 0;
  resultEClosePostClosingFULL: number = 0;
  resultEClosePostClosingHYBRID: number = 0;
  resultECloseEstCureCosts: number = 0;
  resultPaperNetRev: number = 0;
  resultECloseNetRevFULL: number = 0;
  resultECloseNetRevHYBRID: number = 0;
  barDifferenceFULL: number = 0;
  barDifferenceHYBRID: number = 0;
  roiFULL: number = 0;
  roiHYBRID: number = 0;
  savingsFULL: number = 0;
  savingsHYBRID: number = 0;

  resultECloseFee: number = 0; // need input for this to add to database

  BarChartFULL = [];
  BarChartHYBRID = [];

  // prepared for
  PFLender: string = '';
  PFName: string = '';
  PFTitle: string = '';
  PFEmail: string = '';
  PFDate: any = '';

  imgData: any;
  currentDisplayName: string = '';

  sendEmailCollectionRef: AngularFirestoreCollection<SendEmail>;
  UserCollectionRef: AngularFirestoreCollection<User>;
  userDoc: AngularFirestoreDocument<User>;

  testObject = {
    'this is the key': 'and the value',
    'second key': 'yu'
  };

  constructor(
    private dataService:DataService,
    private afs: AngularFirestore,
    public auth: AuthService,
    public afAuth: AngularFireAuth,
    public db: FirestoreService) {

      this.sendEmailCollectionRef = this.afs.collection<SendEmail>('SendEmails');
      this.UserCollectionRef = this.afs.collection<User>('Users');
      this.userDoc = this.UserCollectionRef.doc(this.afAuth.auth.currentUser.email);

    }


  ngOnInit() {


    this.dataService.broadcastECloseType.subscribe((data) => {
      this.eCloseType = data;
    });

    this.dataService.broadcastBankInfo.subscribe(
      (bank) => {

        if (bank === ''){
          console.log('bank empty');
        } else {
          const bankKey = bank[0];
          console.log('bank');
          console.log(bankKey);
          this.PFLender = bankKey['lenderAccountName'];
          this.PFName = bankKey['contactName'];
          this.PFTitle = bankKey['contactTitle'];
          this.PFEmail = bankKey['contactEmail'];
          this.PFDate = this.dataService.timestamp();
        }

    });

    this.dataService.broadcastResults.subscribe(
      data => {
        if (data === '') {
          console.log('empty');
        } else {
          const theKey = data[0];

          this.hybridFee = theKey['hybridECloseFee'];
          this.c12 = theKey['annualLoanVolume'];
          this.c13 = theKey['annualLoanSize'];
          this.c23 = theKey['paperCollateral'];
          this.c24 = theKey['paperShipping'];
          this.c25 = theKey['paperOtherCosts'];
          this.c26 = theKey['paperNotary'];
          this.c28 = theKey['paperDoc'];
          this.c29 = theKey['paperLoan'];
          this.c30 = theKey['paperCustodial'];
          this.c32 = theKey['paperTrailing'];
          this.c32e = theKey['eCloseTrailing'];
          this.c33 = theKey['paperOtherPosts'];
          this.c36 = Number(theKey['paperIssues']/100);
          this.c37 = theKey['paperSigErrors'];

          this.d23 = theKey['eCloseCollateral'];
          this.d24 = theKey['eCloseShipping'];
          this.d25 = theKey['eCloseOtherCosts'];
          this.d26 = theKey['eCloseNotary'];

          this.d28 = theKey['eCloseDoc'];
          this.d29 = theKey['eCloseLoan'];
          this.d30 = theKey['eCloseCustodial'];
          this.d31 = theKey['eCloseMERS'];
          this.d33 = theKey['eCloseOtherPosts'];

          this.d36 = Number(theKey['eCloseIssues']/100);
          this.d37 = theKey['eCloseSigErrors'];

          this.resultECloseFee = theKey['hybridECloseFee'];


          // Results
          this.resultPaperPreClosing = -(this.c23 + this.c24 + this.c25 + this.c26);
          this.resultPaperPostClosing = -(this.c28 + this.c29 + this.c30 + this.c32 + this.c33);
          this.resultPaperEstCureCosts = -(this.c37 * this.c36)/100;

          this.resultEClosePreClosing = -(this.d23 + this.d24 + this.d25 + this.d26);
          this.resultEClosePostClosingFULL = -(this.d28 + this.c32e + this.d29 + this.d30 + this.d31 +  this.d33);
          this.resultEClosePostClosingHYBRID = -(this.d28 + this.c32e + this.d29 + this.d30 +  this.d33);
          this.resultECloseEstCureCosts = -(this.d37 * this.d36 )/100;

          this.resultPaperNetRev = (this.resultPaperPreClosing + this.resultPaperPostClosing + this.resultPaperEstCureCosts);

          this.resultECloseNetRevFULL = (
            this.resultEClosePreClosing +
            this.resultEClosePostClosingFULL +
            this.resultECloseEstCureCosts +
            - this.hybridFee
          );

          this.resultECloseNetRevHYBRID = (
            this.resultEClosePreClosing +
            this.resultEClosePostClosingHYBRID +
            this.resultECloseEstCureCosts +
            - this.hybridFee
          );

          this.barDifferenceFULL = (
            this.resultPaperNetRev -
            this.resultECloseNetRevFULL
          );

          this.barDifferenceHYBRID = (
            this.resultPaperNetRev -
            this.resultECloseNetRevHYBRID
          );

          this.roiFULL = (
            ((this.resultECloseNetRevFULL -
            this.resultPaperNetRev) /
            17.5)
          );

        this.roiHYBRID = (
          ((this.resultECloseNetRevHYBRID - this.resultPaperNetRev) / 17.5)
        );

        this.savingsFULL = (
          ((this.resultECloseNetRevFULL -
          this.resultPaperNetRev) *
          5000)
        );

        this.savingsHYBRID = (
          ((this.resultECloseNetRevHYBRID -
          this.resultPaperNetRev) *
          5000)
        );
      }

      // Bar chart FULL
      this.BarChartFULL = new Chart('barChartFULL', {
        type: 'bar',
        data: {
        labels: [
          'Paper Close (' + Number(this.resultPaperNetRev).toFixed(2) + ')',
          'eClose (' + Number(this.resultECloseNetRevFULL).toFixed(2) + ')',
          'Delta ' + -Number(this.barDifferenceFULL).toFixed(2) + ''
        ],
        datasets: [{
          data: [this.resultPaperNetRev, this.resultECloseNetRevFULL, -this.barDifferenceFULL],
          backgroundColor: [
              '#eee',
              '#283c46',
              '#85dce6'
          ],
          borderWidth: 1
        }]
      },
      options: {
      legend: {
        display: false
      },
      title: {
        text: "eClose vs Paper Close - Home Equity",
        display: true
      },
      scales: {
        yAxes: [{
          ticks: {
            beginAtZero: true
          }
        }]
      },
      responsive: true,
        events: []
      }     });

      // Bar chart HYBRID
      this.BarChartHYBRID = new Chart('barChartHYBRID', {
        type: 'bar',
        data: {
          labels: [
            'Paper Close (' + Number(this.resultPaperNetRev).toFixed(2) + ')',
            'eClose (' + Number(this.resultECloseNetRevHYBRID).toFixed(2) + ')',
            'Delta ' + -Number(this.barDifferenceHYBRID).toFixed(2) + ''
          ],
          datasets: [{
            label: false,
            data: [this.resultPaperNetRev, this.resultECloseNetRevHYBRID, -this.barDifferenceHYBRID],
            backgroundColor: [
              '#eee',
              '#283c46',
              '#85dce6'
            ],
            borderWidth: 1
          }]
        },
        options: {
          legend: {
            display: false
          },
          title: {
            text: '',
            display: false
          },
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true
              }
            }]
          },
          responsive: true,
          events: []
        }
      });

      },
      err => {
        console.error('Error : '+ err);
      },
      () => {
        console.log('cast completed');
      }
    );

  }
  // end of ng onit

  emailResults() {
    console.log('clicked the email button');

    const time = this.dataService.timestamp();
    this.base64Name = 'calcImage-'+time;

    document.querySelector('.print .icon.icon--email .fas').classList.remove('fa-envelope');
    document.querySelector('.print .icon.icon--email .fas').classList.add('fa-spinner');
    document.querySelector('.print .icon.icon--email .fas').classList.add('fa-spin');

    html2canvas(document.querySelector('#snapResults'),{
      windowWidth: 2200,
      ignoreElements: true
    }).then(canvas => {
        // document.body.appendChild(canvas);
        console.log(canvas);
        this.imgData = canvas.toDataURL('image/jpg');
    }).then( () => {
      // we have a canvas base64 so push it to firebase storage
      const storageRef = firebase.storage().ref().child(this.base64Name + '.b64');
      const uploadTask = storageRef.putString(this.imgData);

      uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
        (snapshot) =>  {
          // upload in progress
          console.log('snapshot');
          console.log(snapshot);
        },
        (error) => {
          // upload failed
          console.log('we have an error on the upload');
          console.log(error);
        },
        () => {
          // upload success
          console.log('we have a success on the upload in the home eq')
          document.querySelector('.print .icon.icon--email .fas').classList.remove('fa-spin');
          document.querySelector('.print .icon.icon--email .fas').classList.remove('fa-spinner');
          document.querySelector('.print .icon.icon--email .fas').classList.add('fa-envelope');
          this.addSendEmail(this.base64Name)
        }
      );

    });
  }

  addSendEmail(id: any) {
    console.log('sendEmail function');

    this.userDoc.ref.get().then(function(doc) {
      if (doc.exists) {
        const currentDisplayName = doc.data().displayName;
        console.log('display Name in the addSendEmail');
        console.log(currentDisplayName);
        return currentDisplayName;
      } else {
        const currentDisplayName = 'a Docutech sales team member';
        console.log('display Name in the addSendEmail');
        console.log(currentDisplayName);
        return currentDisplayName;
      }

    }).then( (currentDisplayName) =>{

      const sendEmailID = this.afs.collection('SendEmails').doc(id);
      const setWithMerge = sendEmailID.set({
        clientName: this.PFName,
        clientEmail: this.PFEmail,
        LenderName: this.PFLender,
        clientTitle: this.PFTitle,
        salesPerson: currentDisplayName,
        createdAt: this.dataService.timestamp(),
        imageDownloadLink: '',
        emailSent: false,
        uploadReference: id
      }, {merge: true})
      .then(function(docRef) {
        console.log('Document successfully written!');
      })
      .catch(function(error) {
        console.error('Error writing document: ', error);
      });
    // end of the userDoc ref
    })
    .catch(function(error) {
      console.error('Error getting document: ', error);
    });
  }

  // Found here https://stackoverflow.com/questions/51487689/angular-5-how-to-export-data-to-csv-file
  downloadFile(data: any) {
    const replacer = (key: any, value: any) => value === null ? '' : value; // specify how you want to handle null values here
    const header = Object.keys(data[0]);
    const csv = data.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
    csv.unshift(header.join(','));
    const csvArray = csv.join('\r\n');

    const a = document.createElement('a');
    const blob = new Blob([csvArray], {type: 'text/csv' }),
    url = window.URL.createObjectURL(blob);

    a.href = url;
    a.download = 'myFile.csv';
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

}
