import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../core/auth.service';
import { ReactiveFormsModule, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { inviteUser } from '../../interfaces';

@Component({
  selector: 'app-user-invite',
  templateUrl: './user-invite.component.html',
  styleUrls: ['./user-invite.component.scss']
})
export class UserInviteComponent implements OnInit {
  inviteUserForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private auth: AuthService) { }

  ngOnInit() {
    this.buildForm();
  }

  buildForm(): void {
    this.inviteUserForm = this.fb.group({
        'inviteEmail': ['', [
            Validators.required,
            Validators.email,
        ]],
    });
  }

  private inviteUser(name){

    const inName = this.inviteUserForm.value['inviteEmail'];

    const invite = '12345';

    this.auth.sendRegistrationLink(inName, invite);

    // const authPassw = firebase.auth();
    // let valEmail=this.resetEmailForm.value['emailToReset'];
    // console.log("heree", this.success)
  }
}
