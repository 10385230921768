import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'account-delete',
  templateUrl: './account-delete.component.html',
  styleUrls: ['./account-delete.component.scss']
})
export class AccountDeleteComponent implements OnInit, OnDestroy {

  constructor() { }

  ngOnInit() {
    // set the body class
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('account-delete-page');
  }

  ngOnDestroy() {
    // set the body class
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('account-delete-page');
  }

}
