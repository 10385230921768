import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../core/auth.service';

import { ReactiveFormsModule } from '@angular/forms';


@Component({
  selector: 'user-create',
  templateUrl: './user-create.component.html',
  styleUrls: ['./user-create.component.scss']
})

export class UserCreateComponent implements OnInit, OnDestroy {

  constructor(public auth: AuthService,
              private router: Router) { }


  ngOnInit() {
    // set the body class
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('create-user');
    console.log('auth');
    console.log(this.auth);
  }

  ngOnDestroy() {
    // remove the body class
    // set the body class
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('create-user');
  }

}
