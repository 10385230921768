import { Component, OnInit } from '@angular/core';

import { DataService } from '../shared/data.service';

import * as html2canvas from 'html2canvas';

import { AngularFirestore } from 'angularfire2/firestore';
import * as firebase from 'firebase';

// import * as Base64topjpg from 'base64-to-jpg';
// import * as fs from 'file-system';

@Component({
  selector: 'app-calculator-results',
  templateUrl: './calculator-results.component.html',
  styleUrls: ['./calculator-results.component.scss']
})
export class CalculatorResultsComponent implements OnInit {

  result: any;
  mortgageLoan: boolean;
  eCloseType: boolean;
  isWarehouse: boolean;
  imgData: any;
  b64: any;

  constructor(
    private dataService:DataService,
    private af: AngularFirestore,
   ) { }

  annualLoanV: number = 0;

  download() {

      const time = this.dataService.timestamp();
      html2canvas(document.querySelector('#snapResults'),{
        windowWidth: 768
      }
        ).then(canvas => {
          document.body.appendChild(canvas);
          console.log(canvas);

          this.imgData = canvas.toDataURL('image/jpg');
          console.log(this.imgData);
          console.log(typeof this.imgData);

          // To push base64 string to firestore
          const storageRef = firebase.storage().ref().child('calcImage-' + time + '.b64');
          const metadata = {
            contentType: 'image/jpeg',
          };

          if (this.imgData) {
            storageRef.putString(this.imgData).then(function(snapshot) {
                  console.log('It worked');
            });
          }
      });
  }

  ngOnInit() {

    // this.dataService.doMath();

    // Loan Information is being broadcast from the service, and now this is subscribing to any changes
    this.dataService.broadcastLoanType.subscribe((data) => {
      this.mortgageLoan = data;
    });
    this.dataService.broadcastECloseType.subscribe((data) => {
      this.eCloseType = data;
    });
    this.dataService.broadcastWarehouse.subscribe((data) => {
      this.isWarehouse = data;
    });
  }
}
