import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/auth.guard';

// start page
import { LandingPageComponent } from './ui/landing-page/landing-page.component';
// create user login
import { UserLoginComponent } from './ui/user-login/user-login.component';
// create user register - admin only
import { UserRegisterComponent } from './ui/user-register/user-register.component';

import { UserCreateComponent } from './ui/user-create/user-create.component';
import { UpdateRatesComponent } from './ui/update-rates/update-rates.component';
// dashboard page
import { UserDashboardComponent } from './ui/user-dashboard/user-dashboard.component';
//reset-password page
import { UserForgotPasswordComponent } from './ui/user-forgot-password/user-forgot-password.component';

//user invite page
import { UserInviteComponent } from './ui/user-invite/user-invite.component';

import { CalculatorDashboardComponent } from './calculator/calculator-dashboard/calculator-dashboard.component';
import { CalculatorComponent } from './calculator/calculator-display/calculator.component';

// account page
import { UserAccountComponent } from './ui/user-account/user-account.component';

import { Lost404Component } from './ui/404/404.component';
import { CoreModule } from './core/core.module';

const routes: Routes = [
    { path: '', component: LandingPageComponent },
    { path: 'login', component: UserLoginComponent },
    { path: 'register', component: UserRegisterComponent },

    { path: 'admin/create-user', component: UserCreateComponent },

    { path: 'admin/update-rates', component: UpdateRatesComponent },
    
    // send user to dashboard. login needed
    { path: 'dashboard', component: UserDashboardComponent, canActivate: [AuthGuard] },

    { path: 'reset-password', component: UserForgotPasswordComponent},

    { path: 'invite-user', component: UserInviteComponent},

    // send user to calculator dashboard. login needed
    { path: 'calculator', component: CalculatorDashboardComponent, canActivate: [AuthGuard] },
    { path: 'calculator/create-roi-calculation', component: CalculatorComponent, canActivate: [AuthGuard] },
    
    // send user to account. login needed
    { path: 'account', component: UserAccountComponent, canActivate: [AuthGuard] },

    // otherwise redirect to 404
    { path: '**', component: Lost404Component }
   
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers: [AuthGuard]
})
export class AppRoutingModule {}
