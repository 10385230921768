import { Component, OnInit } from '@angular/core';
import { DataService } from '../../shared/data.service';

import { Observable, of } from 'rxjs';
import { switchMap, tap, startWith } from 'rxjs/operators';

import {Chart} from 'chart.js';
import * as html2canvas from 'html2canvas';

// import the database stuff
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from 'angularfire2/firestore';

import * as firebase from 'firebase';

import { AuthService } from '../../../core/auth.service';
import { AngularFireAuth } from 'angularfire2/auth';

import { SendEmail, User, Rates } from '../../../interfaces';


@Component({
  selector: 'mortgage-results',
  templateUrl: './mortgage-results.component.html',
  styleUrls: ['./mortgage-results.component.scss']
})
export class MortgageResultsComponent implements OnInit {

  eCloseType: boolean;
  WarehouseType: boolean;
  loanType: boolean;

  base64Name: string;
  uploadReference: string;
  curMortEClose: number;
  ratesCollectionRef: AngularFirestoreCollection<Rates>;

  overallCOC: number = .15; // need to add into form for Database pull (along with Libor, Prime, 10Y)

  // INPUTS
  c12: number; // annual loan volume
  c13: number; // annual loan size
  c23: number;
  c24: number;
  c25: number;
  c26: number;
  c28: number;
  c29: number;
  c30: number;
  c32: number;
  c32e: number;
  c33: number;
  c36: number;
  c37: number;

  d23: number;
  d24: number;
  d25: number;
  d26: number;
  d28: number;
  d29: number;
  d30: number;
  d31: number;
  d33: number;
  d36: number;
  d37: number;

  // WAREHOUSE
  c47: number; // base rate
  c48: number; // margin over base
  c49: number; // origination fee
  c50: number; // haircut
  c51: number; // excess wh line
  c52: number; // wh line liquidy
  c54: number; // paper days in warehouse
  c55: number; // eclose days in warehouse
  c57: number; // note rate
  c58: number; // early delivery
  c59: number; // % product early deliver

  // RESULTS
  resultPaperNetInterestIncome: number = 0;
  resultFullNetInterestIncome: number = 0;
  resultHybridNetInterestIncome: number = 0;
  resultFullEarlyDelivery: number = 0;
  resultHybridEarlyDelivery: number = 0;
  resultPaperWarehouseLine: number = 0; // Fixed cost of warehouse Line
  resultFullWarehouseLine: number = 0;
  resultHybridWarehouseLine: number = 0;
  resultPaperCOC: number = 0;
  resultFullCOC: number = 0;
  resultHybridCOC: number = 0;
  resultPaperPreClosing: number = 0;
  resultFullPreClosingFees: number = 0;
  resultHybridPreClosingFees: number = 0;
  resultPaperPostClosingFees: number = 0;
  resultFullPostClosingFees: number = 0;
  resultHybridPostClosingFees: number = 0;
  resultPaperCureCosts: number = 0;
  resultECloseCureCosts: number = 0;
  resultECloseFee: number = 0;
  resultPaperNetRev: number = 0;
  resultFullNetRev: number = 0;
  resultHybridNetRev: number = 0;

  resultFullROI: number = 0;
  resultHybridROI: number = 0;

  resultFullSavings: number = 0;
  resultHybridSavings: number = 0;

  barDifferenceFull: number = 0;
  barDifferenceHybrid: number = 0;
  BarChartFULL: any = [];
  BarChartHYBRID: any = [];

  // prepared for
  PFLender: string = '';
  PFName: string = '';
  PFTitle: string = '';
  PFEmail: string = '';
  PFDate: any = '';

  imgData: any;
  currentDisplayName: string = '';

  sendEmailCollectionRef: AngularFirestoreCollection<SendEmail>;
  UserCollectionRef: AngularFirestoreCollection<User>;
  userDoc: AngularFirestoreDocument<User>;

  constructor(
    private dataService:DataService,
    private afs: AngularFirestore,
    public auth: AuthService,
  public afAuth: AngularFireAuth
  ) {

    this.sendEmailCollectionRef = this.afs.collection<SendEmail>('SendEmails');
    this.UserCollectionRef = this.afs.collection<User>('Users');
    this.userDoc = this.UserCollectionRef.doc(this.afAuth.auth.currentUser.email);

  }

  ngOnInit() {

      this.dataService.broadcastECloseType.subscribe((data) => {
          this.eCloseType = data;
          console.log('eclose type');
          console.log(this.eCloseType);
      });

      this.dataService.broadcastLoanType.subscribe((data) => {
        this.loanType = data;
        console.log('loan type');
        console.log(this.eCloseType);
      });

      this.dataService.broadcastWarehouse.subscribe((data) => {
          this.WarehouseType = data;
          console.log('warehouse type');
          console.log(this.WarehouseType);
      });

      this.dataService.broadcastBankInfo.subscribe(
        (bank) => {

          if(bank === ''){
            console.log('bank empty');
          } else {
            const bankKey = bank[0];
            console.log('bank');
            console.log(bankKey);
            this.PFLender = bankKey['lenderAccountName'];
            this.PFName = bankKey['contactName'];
            this.PFTitle = bankKey['contactTitle'];
            this.PFEmail = bankKey['contactEmail'];
            this.PFDate = this.dataService.timestamp();
          }


      });

      this.dataService.broadcastResults.subscribe(

      data => {
        if (data === '') {
          console.log('empty');
        } else {
          const theKey = data[0];
          console.log('the data is here');
          console.log(data[0]);

          this.eCloseType = theKey['togFullHybrid'];
          this.loanType = theKey['togMortHomeEq'];
          // CALC INPUTS
          this.c12 = theKey['annualLoanVolume'];
          this.c13 = theKey['annualLoanSize'];
          this.c23 = theKey['paperCollateral'];
          this.c24 = theKey['paperShipping'];
          this.c25 = theKey['paperOtherCosts'];
          this.c26 = theKey['paperNotary'];
          this.c28 = theKey['paperDoc'];
          this.c29 = theKey['paperLoan'];
          this.c30 = theKey['paperCustodial'];
          this.c32 = theKey['paperTrailing'];
          this.c32e = Number(theKey['eCloseTrailing']);
          this.c33 = theKey['paperOtherPosts'];
          this.c36 = Number(theKey['paperIssues']/100);
          this.c37 = theKey['paperSigErrors'];

          // WAREHOUSE INPUTS
          this.c47 = Number(theKey['warehouseBaseRate'] / 100);               // base rate
          this.c48 = theKey['warehouseMarginOverBase'];         // margin over base
          this.c49 = Number(theKey['warehouseOrigination']);            // origination fee
          this.c50 = Number(theKey['warehouseHaircut']);                // haircut
          this.c51 = Number(theKey['warehouseExcess'] / 100);                 // excess wh line
          this.c52 = Number(theKey['warehouseLineLiq'] / 100);                // wh line liquidy
          this.c54 = theKey['warehousePaperClose'];             // paper days in warehouse
          this.c55 = theKey['warehouseEClose'];                 // eclose days in warehouse
          this.c57 = Number(theKey['warehouseNoteRate']);
          // note rate
          this.c58 = Number(theKey['warehouseEarlyDelivPrem']);         // early delivery
          this.c59 = Number(theKey['warehousePercentEarlyDelivPrem']);  //% product early deliver

          this.d23 = theKey['eCloseCollateral'];
          this.d24 = theKey['eCloseShipping'];
          this.d25 = theKey['eCloseOtherCosts'];
          this.d26 = theKey['eCloseNotary'];

          this.d28 = theKey['eCloseDoc'];
          this.d29 = theKey['eCloseLoan'];
          this.d30 = theKey['eCloseCustodial'];
          this.d31 = theKey['eCloseMERS'];
          this.d33 = theKey['eCloseOtherPosts'];

          this.d36 = Number(theKey['eCloseIssues'] / 100);
          this.d37 = theKey['eCloseSigErrors'];


          // **************
          // RESULTS
          // **************

          // FEES
          this.resultPaperPreClosing = -(this.c23 + this.c24 + this.c25 + this.c26);
          this.resultFullPreClosingFees = -(this.d23 + this.d24 + this.d25 + this.d26);
          this.resultHybridPreClosingFees = -(this.d23 + this.d24 + this.d25 + this.d26);

          this.resultPaperPostClosingFees = -(this.c28 + this.c29 + this.c30 + this.c32 + this.c32e + this.c33);
          this.resultFullPostClosingFees = -(this.d33 + this.d36 + this.d37 + this.c32e);
          this.resultHybridPostClosingFees = -(this.d28 + this.d29 + this.d30 + this.d33 + this.c32e);

          this.resultPaperCureCosts = -(this.c37 * this.c36);
          this.resultECloseCureCosts = -(this.d36 * this.d37);

          if(this.eCloseType === true) {
            this.resultECloseFee = theKey['fullECloseFee'];
          } else {
            this.resultECloseFee = theKey['hybridECloseFee'];
          }

          // MORTGAGE LOAN WITH NO WAREHOUSE
          if(this.loanType === true) {
            this.resultPaperNetInterestIncome = 0;
            this.resultFullNetInterestIncome = 0;
            this.resultHybridNetInterestIncome = 0;

            this.resultFullEarlyDelivery = 0;
            this.resultHybridEarlyDelivery = 0;

            this.resultPaperWarehouseLine = ((((this.c12 * this.c13 / 12) / ((365 / 12) / this.c52)) * (1 + this.c51)) * 0) / this.c12;
            this.resultFullWarehouseLine = -(((((this.c12 * this.c13 / 12) / ((365 / 12) / this.c55)) * (1 + this.c51)) * 0) / this.c12);
            this.resultHybridWarehouseLine = -(((((this.c12 * this.c13 / 12) / ((365 / 12) / this.c55)) * (1 + this.c51)) * 0) / this.c12);

            this.resultPaperCOC = -((((0 + 0)) * this.overallCOC) / this.c12);
            this.resultFullCOC = -((((0 + 0)) * this.overallCOC) / this.c12);
            this.resultHybridCOC = -((((0 + 0)) * this.overallCOC) / this.c12);

            // (O13:O23)
            // this.resultNetInterestIncome+
            this.resultPaperNetRev = Number(
              this.resultPaperNetInterestIncome +
              this.resultPaperWarehouseLine +
              this.resultPaperCOC +
              this.resultPaperPreClosing +
              this.resultPaperPostClosingFees +
              this.resultPaperCureCosts);

            this.resultFullNetRev = Number(
              this.resultFullNetInterestIncome +
              this.resultFullEarlyDelivery +
              this.resultFullWarehouseLine +
              this.resultFullCOC +
              this.resultFullPreClosingFees +
              this.resultFullPostClosingFees +
              this.resultECloseCureCosts -
              this.resultECloseFee);

            this.resultHybridNetRev = Number(
              this.resultHybridNetInterestIncome +
              this.resultHybridEarlyDelivery +
              this.resultHybridWarehouseLine +
              this.resultHybridCOC +
              this.resultHybridPreClosingFees +
              this.resultHybridPostClosingFees +
              this.resultECloseCureCosts -
              this.resultECloseFee);

            this.resultFullROI = Number( (this.resultFullNetRev - this.resultPaperNetRev) / this.resultECloseFee );
            this.resultHybridROI = Number( (this.resultHybridNetRev - this.resultPaperNetRev) / this.resultECloseFee );

            this.resultFullSavings = Number( (this.resultFullNetRev - this.resultPaperNetRev) * this.c12 );
            this.resultHybridSavings = Number( (this.resultHybridNetRev - this.resultPaperNetRev) * this.c12 );

            this.barDifferenceFull = Number(
              this.resultPaperNetRev -
              this.resultFullNetRev);

            this.barDifferenceHybrid = Number(
              this.resultPaperNetRev -
              this.resultHybridNetRev);

            // Bar chart FULL
            this.BarChartFULL = new Chart('barChartFULL', {
              type: 'bar',
              data: {
                labels: [
                  'Paper Close (' + Number(this.resultPaperNetRev).toFixed(2) + ')',
                  'eClose (' + Number(this.resultFullNetRev).toFixed(2) + ')',
                  'Delta ' + -Number(this.barDifferenceFull).toFixed(2) + ''
                ],
                datasets: [{
                  data: [this.resultPaperNetRev, this.resultFullNetRev, -this.barDifferenceFull],
                  backgroundColor: [
                    '#eee',
                    '#283c46',
                    '#85dce6'
                  ],
                  // borderColor: [
                  // 'rgba(255, 99, 132, 1)',
                  // 'rgba(255, 99, 132, 1)',
                  // 'rgba(255, 99, 132, 1)',
                  // 'rgba(255, 99, 132, 1)',
                  // 'rgba(255, 99, 132, 1)',
                  // 'rgba(255, 99, 132, 1)',
                  // ],
                  borderWidth: 1
                }]
              },
              options: {
                legend: {
                  display: false
                },
                title: {
                  text: '',
                  display: false
                },
                scales: {
                  yAxes: [{
                    ticks: {
                      beginAtZero: true
                    }
                  }]
                },
                responsive: true,
                events: []
              }
            });

            // Bar chart Hybrid
            this.BarChartHYBRID = new Chart('BarChartHYBRID', {
              type: 'bar',
              data: {
                // labels: ["Paper Close", "eClose", "Delta"],
                labels: [
                  'Paper Close (' + Number(this.resultPaperNetRev).toFixed(2) + ')',
                  'eClose (' + Number(this.resultHybridNetRev).toFixed(2) + ')',
                  'Delta ' + -Number(this.barDifferenceHybrid).toFixed(2) + ''
                ],
                datasets: [{
                  data: [this.resultPaperNetRev, this.resultHybridNetRev, -this.barDifferenceHybrid],
                  backgroundColor: [
                    '#eee',
                    '#283c46',
                    '#85dce6'
                  ],
                  // borderColor: [
                  // 'rgba(255, 99, 132, 1)',
                  // 'rgba(255, 99, 132, 1)',
                  // 'rgba(255, 99, 132, 1)',
                  // 'rgba(255, 99, 132, 1)',
                  // 'rgba(255, 99, 132, 1)',
                  // 'rgba(255, 99, 132, 1)',
                  // ],
                  borderWidth: 1
                }]
              },
              options: {
                legend: {
                  display: false
                },
                title: {
                  text: '',
                  display: false
                },
                scales: {
                  yAxes: [{
                    ticks: {
                      beginAtZero: true
                    }
                  }]
                },
                responsive: true,
                events: []
              }
            });


          }

          // MORTGAGE LOAN WITH WAREHOUSE
          if ( this.loanType === true && this.WarehouseType === true ) {

            this.resultPaperNetInterestIncome = (this.c13 * this.c54 / 365 * this.c57) - (this.c13 * (1 - this.c50) * this.c54 / 365 * (this.c47 + (this.c48 / 10000)));
            this.resultFullNetInterestIncome = ((this.c13 * this.c55 / 365 * this.c57) - (this.c13 * (1 - this.c50) * this.c55 / 365 * (this.c47 + (this.c48 / 10000))));
            this.resultHybridNetInterestIncome = ((this.c13 * this.c55 / 365 * this.c57) - (this.c13 * (1 - this.c50) * this.c55 / 365 * (this.c47 + (this.c48 / 10000))));


            this.resultFullEarlyDelivery = this.c13 * this.c58 / 100 * this.c59;
            this.resultHybridEarlyDelivery = this.c13 * this.c58 / 100 * this.c59;

            this.resultPaperWarehouseLine = -(((((this.c12 * this.c13 / 12) / ((365 / 12) / this.c54)) * (1 + this.c51)) * this.c49) / this.c12);
            this.resultFullWarehouseLine = -(((((this.c12 * this.c13 / 12) / ((365 / 12) / this.c55)) * (1 + this.c51)) * this.c49) / this.c12);
            this.resultHybridWarehouseLine = -(((((this.c12 * this.c13 / 12) / ((365 / 12) / this.c55)) * (1 + this.c51)) * this.c49) / this.c12);

            this.resultPaperCOC = -(((((((((this.c12 * this.c13 / 12) / ((365 / 12) / this.c54)) * (1 + this.c51)) * this.c50)) + (((((this.c12 * this.c13 / 12) / ((365 / 12) / this.c54)) * (1 + this.c51)) * this.c52)))) * this.overallCOC) / this.c12);
            this.resultFullCOC = -(((((((((this.c12 * this.c13 / 12) / ((365 / 12) / this.c55)) * (1 + this.c51)) * this.c50)) + (((((this.c12 * this.c13 / 12) / ((365 / 12) / this.c55)) * (1 + this.c51)) * this.c52)))) * this.overallCOC) / this.c12);
            this.resultHybridCOC = -(((((((((this.c12 * this.c13 / 12) / ((365 / 12) / this.c55)) * (1 + this.c51)) * this.c50)) + ((((this.c12 * this.c13 / 12) / ((365 / 12) / this.c55)) * (1 + this.c51)) * this.c52))) * this.overallCOC) / this.c12);

            this.resultPaperNetRev =
              this.resultPaperNetInterestIncome +
              this.resultPaperWarehouseLine +
              this.resultPaperCOC +
              this.resultPaperPreClosing +
              this.resultPaperPostClosingFees +
              this.resultPaperCureCosts;

            this.resultFullNetRev =
              this.resultFullNetInterestIncome +
              this.resultFullEarlyDelivery +
              this.resultFullWarehouseLine +
              this.resultFullCOC +
              this.resultFullPreClosingFees +
              this.resultFullPostClosingFees +
              this.resultECloseCureCosts -
              this.resultECloseFee;

            this.resultHybridNetRev =
              this.resultHybridNetInterestIncome +
              this.resultHybridEarlyDelivery +
              this.resultHybridWarehouseLine +
              this.resultHybridCOC +
              this.resultHybridPreClosingFees +
              this.resultHybridPostClosingFees +
              this.resultECloseCureCosts -
              this.resultECloseFee;

            //
            this.resultFullROI = (this.resultFullNetRev - this.resultPaperNetRev) / this.resultECloseFee;
            this.resultHybridROI = (this.resultHybridNetRev - this.resultPaperNetRev) / this.resultECloseFee;

            this.resultFullSavings = (this.resultFullNetRev - this.resultPaperNetRev) * this.c12;
            this.resultHybridSavings = (this.resultHybridNetRev - this.resultPaperNetRev) * this.c12;

            this.barDifferenceFull =
              this.resultPaperNetRev -
              this.resultFullNetRev;

            this.barDifferenceHybrid =
              this.resultPaperNetRev -
              this.resultHybridNetRev;

            // Bar chart FULL
            this.BarChartFULL = new Chart('barChartFULL', {
              type: 'bar',
              data: {
                // labels: ["Paper Close", "eClose", "Delta"],
                labels: [
                  'Paper Close (' + Number(this.resultPaperNetRev).toFixed(2) + ')',
                  'eClose (' + Number(this.resultFullNetRev).toFixed(2) + ')',
                  'Delta ' + -Number(this.barDifferenceFull).toFixed(2) + ''
                ],
                datasets: [{
                  data: [this.resultPaperNetRev, this.resultFullNetRev, -this.barDifferenceFull],
                  backgroundColor: [
                    '#eee',
                    '#283c46',
                    '#85dce6'
                  ],
                  // borderColor: [
                  // 'rgba(255, 99, 132, 1)',
                  // 'rgba(255, 99, 132, 1)',
                  // 'rgba(255, 99, 132, 1)',
                  // 'rgba(255, 99, 132, 1)',
                  // 'rgba(255, 99, 132, 1)',
                  // 'rgba(255, 99, 132, 1)',
                  // ],
                  borderWidth: 1
                }]
              },
              options: {
                legend: {
                  display: false
                },
                title: {
                  text: '',
                  display: false
                },
                scales: {
                  yAxes: [{
                    ticks: {
                      beginAtZero: true
                    }
                  }]
                },
                responsive: true,
                events: []
              }
            });

            // Bar chart Hybrid
            this.BarChartHYBRID = new Chart('BarChartHYBRID', {
              type: 'bar',
              data: {
                // labels: ["Paper Close", "eClose", "Delta"],
                labels: [
                  'Paper Close (' + Number(this.resultPaperNetRev).toFixed(2) + ')',
                  'eClose (' + Number(this.resultHybridNetRev).toFixed(2) + ')',
                  'Delta ' + -Number(this.barDifferenceHybrid).toFixed(2) + ''
                ],
                datasets: [{
                  data: [this.resultPaperNetRev, this.resultHybridNetRev, -this.barDifferenceHybrid],
                  backgroundColor: [
                    '#eee',
                    '#283c46',
                    '#85dce6'
                  ],
                  // borderColor: [
                  // 'rgba(255, 99, 132, 1)',
                  // 'rgba(255, 99, 132, 1)',
                  // 'rgba(255, 99, 132, 1)',
                  // 'rgba(255, 99, 132, 1)',
                  // 'rgba(255, 99, 132, 1)',
                  // 'rgba(255, 99, 132, 1)',
                  // ],
                  borderWidth: 1
                }]
              },
              options: {
                legend: {
                  display: false
                },
                title: {
                  text: '',
                  display: false
                },
                scales: {
                  yAxes: [{
                    ticks: {
                      beginAtZero: true
                    }
                  }]
                },
                responsive: true,
                events: []
              }
            });



          }
        }
      },
      err => {
        console.error('Error : ' + err);
      },
      () => {
        console.log('cast completed');
      }
    );
  }
  // end of init





  emailResults() {
      console.log('clicked the email button');

      const time = this.dataService.timestamp();
      this.base64Name = 'calcImage-' + time;
      console.log('base64Name');
      console.log(this.base64Name);

      document.querySelector('.print .icon.icon--email .fas').classList.remove('fa-envelope');
      document.querySelector('.print .icon.icon--email .fas').classList.add('fa-spinner');
      document.querySelector('.print .icon.icon--email .fas').classList.add('fa-spin');

      html2canvas(document.querySelector('#snapResults'), {
        windowWidth: 2200,
        ignoreElements: true

      }).then(canvas => {
          // document.body.appendChild(canvas);
          console.log(canvas);

          this.imgData = canvas.toDataURL('image/jpg');
          console.log(this.imgData);
          console.log(typeof this.imgData);


      }).then( () => {

        // we have a canvas base64 so push it to firebase storage
        const storageRef = firebase.storage().ref().child(this.base64Name + '.b64');
        const uploadTask = storageRef.putString(this.imgData);

        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
          (snapshot) =>  {
            console.log(snapshot)
            // upload in progress
            console.log('snapshot');
            console.log(snapshot);
            // this.uploadReference = uploadTask.snapshot.task.snapshot.metadata.md5Hash;
            // console.log('my reference');
            // console.log(this.uploadReference);
          },
          (error) => {
            // upload failed
            console.log('we have an error on the upload')
            console.log(error)
          },
          () => {
            // upload success
            // this.uploadReference = uploadTask.snapshot.task.snapshot.metadata.md5Hash;
            // console.log('my reference');
            // console.log(this.uploadReference);
            console.log('we have a success on the upload');
            document.querySelector('.print .icon.icon--email .fas').classList.remove('fa-spin');
            document.querySelector('.print .icon.icon--email .fas').classList.remove('fa-spinner');
            document.querySelector('.print .icon.icon--email .fas').classList.add('fa-envelope');
            this.addSendEmail(this.base64Name);
          }
        );


      });
  }

  private addSendEmail(id) {
    console.log('sendEmail function');

    this.userDoc.ref.get().then(function(doc) {

      console.log('getting the userdoc ref')
      console.log(doc.data())

      if (doc.exists) {
        const currentDisplayName = doc.data().displayName;
        console.log('display Name in the addSendEmail');
        console.log(currentDisplayName);
        return currentDisplayName;
      } else {
        const currentDisplayName = 'a Docutech sales team member'
        console.log('display Name in the addSendEmail');
        console.log(currentDisplayName);
        return currentDisplayName;
      }
    }).then( (currentDisplayName) =>{

      const sendEmailID = this.afs.collection('SendEmails').doc(id);
      const setWithMerge = sendEmailID.set({
        clientName: this.PFName,
        clientEmail: this.PFEmail,
        LenderName: this.PFLender,
        clientTitle: this.PFTitle,
        salesPerson: currentDisplayName,
        createdAt: this.dataService.timestamp(),
        imageDownloadLink: '',
        emailSent: false,
        uploadReference: id
      }, {merge: true})
      .then(function(docRef) {
          console.log('Document successfully written!');
      })
      .catch(function(error) {
          console.error('Error writing document: ', error);
      });

    // end of the userDoc get
    });


  // end of the add Send Email
  }



// end of class
}
