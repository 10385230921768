import { Component, OnInit, Input } from '@angular/core';
import {
  ReactiveFormsModule,
  FormGroup,
  FormBuilder,
  Validators
} from '@angular/forms';

import {
  AngularFirestore,
  AngularFirestoreDocument,
  AngularFirestoreCollection
} from 'angularfire2/firestore';

import {
  User,
  Rates
} from '../../interfaces';

import { DataService } from '../shared/data.service';
import { calculatorInputs } from '../../interfaces';

@Component({
  selector: 'app-calculator-form',
  templateUrl: './calculator-form.component.html',
  styleUrls: ['./calculator-form.component.scss']
})
export class CalculatorFormComponent implements OnInit {

  curPrime: number;
  curLibor: number;
  cur10Ycmt: number;
  curRate: string;
  ratesCollectionRef: AngularFirestoreCollection<Rates>;

  constructor(
    private dataService:DataService,
    private fb: FormBuilder,
    private afs: AngularFirestore,
  ) {
      this.ratesCollectionRef = this.afs.collection<Rates>('Rates');
  }

  calcForm: FormGroup;
  togMortHomeEq = true;
  isWarehouse = false;
  togFullHybrid = true;

  indexType: string;
  // we have to set the base rate as a default here.
  // need this in the database
  warehouseIndex: string;
  baseRate: number;

  ngOnInit() {

    this.buildCalcForm();
    this.getRates();

    // 'cast' is being broadcast from the service, and now this is subscribing to any changes
    // this.dataService.cast.subscribe(result=> this.result = result);
  }

  mortgageType() {
    this.togMortHomeEq = !this.togMortHomeEq;
    this.dataService.changeLoanType(this.togMortHomeEq);


    if (this.togFullHybrid) {
    this.ecloseType();
    }


  }
  ecloseType() {
    console.log('is full before: ' , this.togFullHybrid);
    this.togFullHybrid = !this.togFullHybrid;
    this.dataService.changeECloseType(this.togFullHybrid);
    console.log('is full after: ' , this.togFullHybrid);

    if (this.togFullHybrid === true) {
      this.calcForm.get('eCloseCollateral').setValue(0);
      this.calcForm.get('eCloseShipping').setValue(0);
      this.calcForm.get('eCloseOtherCosts').setValue(0);
      this.calcForm.get('eCloseDoc').setValue(0);
      this.calcForm.get('eCloseLoan').setValue(0);
      this.calcForm.get('eCloseCustodial').setValue(0);
    } else {
      this.calcForm.get('eCloseCollateral').setValue( this.calcForm.value['paperCollateral'] / 2);
      this.calcForm.get('eCloseShipping').setValue( this.calcForm.value['paperShipping'] / 2 );
      this.calcForm.get('eCloseOtherCosts').setValue( this.calcForm.value['paperOtherCosts'] / 2 );
      this.calcForm.get('eCloseDoc').setValue( this.calcForm.value['paperDoc'] / 2 );
      this.calcForm.get('eCloseLoan').setValue( this.calcForm.value['paperLoan'] / 2 );
      this.calcForm.get('eCloseCustodial').setValue( this.calcForm.value['paperCustodial'] );

    }

  }

  setEClose(input: any, output: any , multiplier: any) {
    if (this.togFullHybrid === false) {
      this.calcForm.get(output).setValue( this.calcForm.value[input] * multiplier );
    }
  }

  equalInput(input: any , output: any) {
      this.calcForm.get(output).setValue( this.calcForm.value[input] );
  }

  isWarehouseLoan() {
    this.isWarehouse = !this.isWarehouse;
    this.dataService.changeWarehouse(this.isWarehouse);
    console.log('is warehouse: ', this.isWarehouse);
  }

  chooseIndex(indexType: string) {
    this.indexType = indexType;
    if (this.indexType === '10YCMT') {
      this.baseRate = this.cur10Ycmt;
    } else if (this.indexType === 'LIBOR') {
      this.baseRate = this.curLibor;
    } else if (this.indexType === 'Prime') {
      this.baseRate = this.curPrime;
    }
  }

  buildCalcForm() {
    this.calcForm = this.fb.group({
      'togMortHomeEq': [true, [Validators.required
      ]],
      'togFullHybrid': [true, [Validators.required]],
      'isWarehouse': [false, [Validators.required] ],
      'annualLoanVolume': [ , [Validators.required]],
      'annualLoanSize': [ , [Validators.required]],
      'paperCollateral': [30.00, [Validators.required]],
      'eCloseCollateral': [0, [Validators.required]],
      'paperShipping': [75.00, [Validators.required]],
      'eCloseShipping': [0, [Validators.required]],
      'paperOtherCosts': [125.00, [Validators.required]],
      'eCloseOtherCosts': [0, [Validators.required]],
      'paperNotary': [125.00, [Validators.required]],
      'eCloseNotary': [125.00, [Validators.required]],
      'paperDoc': [7.50, [Validators.required]],
      'eCloseDoc': [0, [Validators.required]],
      'paperLoan': [30.00, [Validators.required]],
      'eCloseLoan': [0, [Validators.required]],
      'paperCustodial': [30.00, [Validators.required]],
      'eCloseCustodial': [0, [Validators.required]],
      'eCloseMERS': [5.00, [Validators.required]],
      'paperTrailing': [30.00, [Validators.required]],
      'eCloseTrailing': [0, [Validators.required]],
      'paperOtherPosts': [0, [Validators.required]],
      'eCloseOtherPosts': [0, [Validators.required]],
      'fullECloseFee': [35, [Validators.required]],
      'hybridECloseFee': [15, [Validators.required]],
      'paperIssues': [10.00, [Validators.required]],
      'eCloseIssues': [0.5, [Validators.required]],
      'paperSigErrors': [75.00, [Validators.required]],
      'eCloseSigErrors': [75.00, [Validators.required]],
      // warehouse
      'warehouseIndex': [this.warehouseIndex, [Validators.required]],
      'warehouseBaseRate': [2.815, [Validators.required]],
      // 'warehouseMargin': [30, []],
      'warehouseMarginOverBase': [30.00, [Validators.required]],
      'warehouseOrigination': [0.00375, [Validators.required]],
      'warehouseHaircut': [0.03, [Validators.required]],
      'warehouseExcess': [10.0, [Validators.required]],
      'warehouseLineLiq': [4.0, [Validators.required]],
      'warehousePaperClose': [15.00, [Validators.required]],
      'warehouseEClose': [5.00, [Validators.required]],
      'warehouseNoteRate': [0.05125, [Validators.required]],
      'warehouseEarlyDelivPrem': [.125, [Validators.required]],
      'warehousePercentEarlyDelivPrem': [0.15, [Validators.required]],

    });
  }



  editTheResult() {
    const calcInputs: calculatorInputs = Object.assign({}, this.calcForm.value);
    // go to the top of the window
    window.scrollTo(0, 0);
    this.dataService.doMath(calcInputs);
  }


  /*
  resetForm() {
    this.calcForm.controls['paperSigErrors'].reset()
  }
  */


    private getRates() {
    const self = this;
    this.ratesCollectionRef.doc('allRates').ref.get().then(function(doc){
      if (doc.exists) {
        const currentData = doc.data();
        self.curPrime = currentData['prime'];
        self.curLibor = currentData['libor'];
        self.cur10Ycmt = currentData['tenYcmt'];
        self.curRate = currentData['radioRate'];
        self.forDefault(self.curRate);
      }
    });
  }

  private forDefault(rate: any) {
    if (rate === 'radioPrime') {
      this.warehouseIndex = 'PRIME';
      this.baseRate = this.curPrime;
      this.buildCalcForm();
    } else if (rate === 'radioLibor') {
      this.warehouseIndex = 'LIBOR';
      this.baseRate = this.curLibor;
      this.buildCalcForm();
    } else {
      this.warehouseIndex = '10YCMT';
      this.baseRate = this.cur10Ycmt;
      this.buildCalcForm();
    }
  }
}
