import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
    selector: 'app-landing-page',
    templateUrl: './landing-page.component.html',
    styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit, OnDestroy {

    constructor() {}

    ngOnInit() {
        const body = document.getElementsByTagName('body')[0];
        body.classList.add('landing-page');
    }

    ngOnDestroy() {
        const body = document.getElementsByTagName('body')[0];
        body.classList.remove('landing-page');
    }

    /*
    scrollMe(element) {
        let interval = 0;
        let el = document.getElementById(element);
        let yPos = el.getClientRects()[0].top;
        let yScroll = window.scrollY;
        interval = setInterval(function() {
            yScroll += 12;
            window.scroll(0, yScroll);

            if (el.getClientRects()[0].top <= (0 || (yScroll - yPos))) {
                clearInterval(interval);
            }
        }, 5);

    }
*/

}
