import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// loading spinner, userlist, tabs, avatar
import { SharedModule } from '../../shared/shared.module';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { UserLoginComponent } from '../user-login/user-login.component';

// admin components
import { UserRegisterComponent } from '../user-register/user-register.component';
import { UserFormComponent } from '../user-form/user-form.component';
import { registerFormComponent } from '../register-form/register-form.component';
import { ReportLinkComponent } from '../report-link/report-link.component';

import { UserCreateComponent } from '../user-create/user-create.component';
import { UpdateRatesComponent } from '../update-rates/update-rates.component';
import { CreateUserFormComponent } from '../create-user-form/create-user-form.component';

// import { TopNavComponent } from '../top-nav/top-nav.component';
// import { FooterNavComponent } from '../footer-nav/footer-nav.component';

import { LandingPageComponent } from '../landing-page/landing-page.component';

import { UserProfileComponent } from '../user-profile/user-profile.component';
import { UserDashboardComponent } from '../user-dashboard/user-dashboard.component';
import { UserAccountComponent } from '../user-account/user-account.component';
import { AccountUpdateFormComponent } from '../account-update-form/account-update-form.component';
import { AccountDeleteComponent } from '../account-delete/account-delete.component';


import { Lost404Component } from '../404/404.component';

// Import AvatarModule
import { AvatarModule, AvatarConfig } from 'ngx-avatar';

const avatarColors = [
  '#FFB6C1',
  '#2c3e50',
  '#95a5a6',
  '#f39c12',
  '#1abc9c'
];




@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    BrowserAnimationsModule,
    AvatarModule.forRoot({
      colors: avatarColors
    })
  ],
  declarations: [
    UserLoginComponent,
    UserFormComponent,
    // admin components
    UserRegisterComponent,
    registerFormComponent,
    ReportLinkComponent,
    UserCreateComponent,
    UpdateRatesComponent,
    CreateUserFormComponent,
    // page components
    LandingPageComponent,
    // user components
    UserProfileComponent,
    UserDashboardComponent,
    UserAccountComponent,
    AccountUpdateFormComponent,
    AccountDeleteComponent,
    // catch all component
    Lost404Component
  ],
  exports: [
    UserProfileComponent
  ]
})
export class UiModule { }
