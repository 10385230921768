// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,


  // firebaseConfig: {
  //   apiKey: 'AIzaSyC1JuhIRPZvj3ZZUwDvT3U00U78m19RDaI',
  //   authDomain: 'docutech-roi-calculator-dev.firebaseapp.com',
  //   databaseURL: 'https://docutech-roi-calculator-dev.firebaseio.com',
  //   projectId: 'docutech-roi-calculator-dev',
  //   storageBucket: 'docutech-roi-calculator-dev.appspot.com',
  //   messagingSenderId: '314507948409'
  // }



  // production

  firebaseConfig: {
    apiKey: 'AIzaSyCq51lY7rpuyoEaPXGhHeOP6e98fhKoaYc',
    authDomain: 'solex-eclose-roi-calculator.firebaseapp.com',
    databaseURL: 'https://solex-eclose-roi-calculator.firebaseio.com',
    projectId: 'solex-eclose-roi-calculator',
    storageBucket: 'solex-eclose-roi-calculator.appspot.com',
    messagingSenderId: '489775846358'
  }


};


/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
