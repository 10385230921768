import { environment } from '../environments/environment';
import {
  BrowserModule,
  BrowserTransferStateModule
} from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

//  GOOGLE FIRESTORE
import { AngularFireModule } from 'angularfire2';
import { AngularFirestoreModule } from 'angularfire2/firestore';
import { AngularFireAuthModule } from 'angularfire2/auth';

// Modules
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { UiModule } from './ui/shared/ui.module';
// import { UsersModule } from './users/shared/users.module';

import { FormsModule } from '@angular/forms';

import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { FirestoreService } from './firestore.service';
import { HttpClientModule } from '@angular/common/http';

// import the calculator
import { CalculatorModule } from './calculator/shared/calculator.module';
import { UserForgotPasswordComponent } from './ui/user-forgot-password/user-forgot-password.component';
import { UserInviteComponent } from './ui/user-invite/user-invite.component';

// sweet alerts
import { SweetAlert2Module } from '@toverux/ngx-sweetalert2';

@NgModule({
  declarations: [
    AppComponent,
    UserForgotPasswordComponent,
    UserInviteComponent,
    ],

  imports: [
    BrowserModule,
    BrowserTransferStateModule,
    HttpClientModule,
    AppRoutingModule,
    CoreModule,
    SharedModule,
    UiModule,
    CalculatorModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    FormsModule,
    SweetAlert2Module.forRoot({
      buttonsStyling: false,
      customClass: 'modal-content',
      confirmButtonClass: 'button is-primary',
      cancelButtonClass: 'button'
    }),

    AngularFireModule.initializeApp(environment.firebaseConfig), // imports firebase/app needed for everything
    //AngularFirestoreModule.enablePersistence(), /// <--- this is for offline mode
    AngularFirestoreModule,
    AngularFireAuthModule, // imports firebase/auth, only needed for auth features
  ],
  providers: [FirestoreService],
  bootstrap: [AppComponent]
})
export class AppModule { }
