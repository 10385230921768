import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../core/auth.service';

import { ReactiveFormsModule } from '@angular/forms';


@Component({
  selector: 'user-login',
  templateUrl: './user-login.component.html',
  styleUrls: ['./user-login.component.scss']
})

export class UserLoginComponent implements OnInit, OnDestroy {

  constructor(public auth: AuthService,
              private router: Router) { }


  ngOnInit() {
    // set the body class
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('login-page');
  }


  /// Shared
  private afterSignIn(): void {
    // Do after login stuff here, such router redirects, toast messages, etc.
    this.router.navigate(['/dashboard']);
  }

  ngOnDestroy() {
    // set the body class
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('login-page');
  }

}
