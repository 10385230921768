import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../core/auth.service';
import { ReactiveFormsModule, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { resetPassword } from '../../interfaces';

import swal from 'sweetalert2';

import { User, Roles } from '../../interfaces';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from 'angularfire2/firestore';

 declare var require: any;
 const firebase = require('firebase/app');

@Component({
  selector: 'app-user-forgot-password',
  templateUrl: './user-forgot-password.component.html',
  styleUrls: ['./user-forgot-password.component.scss']
})

export class UserForgotPasswordComponent implements OnInit {
  resetEmailForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private auth: AuthService) { }

  success = false;
  tryAgain = false;
  emailToReset = '';

  ngOnInit() {
    this.buildForm();
  }

  buildForm(): void {
    this.resetEmailForm = this.fb.group({
        'emailToReset': ['', [
            Validators.required,
            Validators.email,
        ]],
        'success': [false, [Validators.required]],
        'tryAgain': [false, [Validators.required]],
    });
  }

  private resetEmail(email) {
    document.querySelector('.create--spinner .icon .fas').classList.add('fa-spinner');
    document.querySelector('.create--spinner .icon .fas').classList.add('fa-spin');
    const authPassw = firebase.auth();
    // if the user is not an admin then use the login in users email
    // else then use the input
    const valEmail = this.resetEmailForm.value['emailToReset'];
    console.log('heree', this.success);

    const that = this;


    authPassw.sendPasswordResetEmail(valEmail)
      .then(function() {
        document.querySelector('.create--spinner .icon .fas').classList.remove('fa-spinner');
        document.querySelector('.create--spinner .icon .fas').classList.remove('fa-spin');
        swal({
          title: 'Success!',
          text: 'A password reset has been sent to ' +valEmail,
          type: 'success',
          confirmButtonText: 'OK',
          confirmButtonColor: '#85dce6',
          confirmButtonClass: 'button is-primary is-uppercase',
          buttonsStyling: false
        })
        that.success = true;
        that.tryAgain = false;

      }).catch(function(error: any) {

        swal({
          title: 'Error!',
          text: 'There was an error in trying to send the password reset. Please try again.',
          type: 'error',
          confirmButtonText: 'OK',
          confirmButtonColor: '#85dce6',
          confirmButtonClass: 'button is-primary is-uppercase',
          buttonsStyling: false
        });
      that.success = false;
      that.tryAgain = true;
    });
  }



}
