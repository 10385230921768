import { Injectable } from '@angular/core';

// Will help us communicate back and forth
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

// For Firestore
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument} from 'angularfire2/firestore';
import { AuthService } from '../../core/auth.service'
import { AngularFireAuth } from 'angularfire2/auth';
import * as firebase from 'firebase';

import { Observable } from 'rxjs/Observable';

import * as html2canvas from 'html2canvas';

import { User } from '../../interfaces';

@Injectable()
export class DataService {

  // bank info
  private bankInfo = new BehaviorSubject<any>('');
  private resultNew = new BehaviorSubject<any>('');
  private toggleLoanType = new BehaviorSubject<boolean>(true);
  private toggleECloseType = new BehaviorSubject<boolean>(true);
  private toggleWarehouse = new BehaviorSubject<boolean>(false);

  // This varibale broadcasts the private variable user
  broadcastResults = this.resultNew.asObservable();

  broadcastBankInfo = this.bankInfo.asObservable();

  // This varibale broadcasts the Loan Information toggles
  broadcastLoanType = this.toggleLoanType.asObservable();
  broadcastECloseType = this.toggleECloseType.asObservable();
  broadcastWarehouse = this.toggleWarehouse.asObservable();

  imgData: any;

  constructor(
    public afs: AngularFirestore,
    private afAuth: AngularFireAuth,
    ) {

  }


  changeLoanType(mortHome: any) {
    console.log(mortHome);
    this.toggleLoanType.next(mortHome);
  }
  changeECloseType(fullHybrid: any) {
    console.log(fullHybrid);
    this.toggleECloseType.next(fullHybrid);
  }
  changeWarehouse(yesNo: any) {
    console.log(yesNo);
    this.toggleWarehouse.next(yesNo);
  }

  timestamp() {
    const today: number = Date.now();
    return today;
  }

  printResults() {
    window.print();
  }



  scrollTop() {
    window.scrollTo(0, 0);
  }

  getBankInfo(BI: any) {
    const bcInfo: Array<any> = [ {
      lenderAccountName: BI.lenderAccountName,
      contactName: BI.contactName,
      contactTitle: BI.contactTitle,
      contactEmail: BI.contactEmail
    }];

    console.log('bcInfo');
    console.log(bcInfo);

    this.bankInfo.next(bcInfo);
  }


  // CI = calcInputs from the form
  doMath(CI: any) {
    // do the math and add keep it in an array
    const resultMath: Array<any> = [
      {
        togMortHomeEq: CI.togMortHomeEq,
        togFullHybrid: CI.togFullHybrid,
        isWarehouse: CI.isWarehouse,
        annualLoanVolume: CI.annualLoanVolume,
        annualLoanSize: CI.annualLoanSize,
        paperCollateral: CI.paperCollateral,
        eCloseCollateral: CI.eCloseCollateral,
        paperShipping: CI.paperShipping,
        eCloseShipping: CI.eCloseShipping,
        paperOtherCosts: CI.paperOtherCosts,
        eCloseOtherCosts: CI.eCloseOtherCosts,
        paperNotary: CI.paperNotary,
        eCloseNotary: CI.eCloseNotary,
        paperDoc: CI.paperDoc,
        eCloseDoc: CI.eCloseDoc,
        paperLoan: CI.paperLoan,
        eCloseLoan: CI.eCloseLoan,
        paperCustodial: CI.paperCustodial,
        eCloseCustodial: CI.eCloseCustodial,
        eCloseMERS: CI.eCloseMERS,
        paperTrailing: CI.paperTrailing,
        eCloseTrailing: CI.eCloseTrailing,
        paperOtherPosts: CI.paperOtherPosts,
        eCloseOtherPosts: CI.eCloseOtherPosts,
        fullECloseFee: CI.fullECloseFee,
        hybridECloseFee: CI.hybridECloseFee,
        paperIssues: CI.paperIssues,
        eCloseIssues: CI.eCloseIssues,
        paperSigErrors: CI.paperSigErrors,
        eCloseSigErrors: CI.eCloseSigErrors,
        warehouseIndex: CI.warehouseIndex,
        warehouseBaseRate: CI.warehouseBaseRate,
        warehouseMarginOverBase: CI.warehouseMarginOverBase,
        warehouseOrigination: CI.warehouseOrigination,
        warehouseHaircut: CI.warehouseHaircut,
        warehouseExcess: CI.warehouseExcess,
        warehouseLineLiq: CI.warehouseLineLiq,
        warehousePaperClose: CI.warehousePaperClose,
        warehouseEClose: CI.warehouseEClose,
        warehouseNoteRate: CI.warehouseNoteRate,
        warehouseEarlyDelivPrem: CI.warehouseEarlyDelivPrem,
        warehousePercentEarlyDelivPrem: CI.warehousePercentEarlyDelivPrem
      }
    ];
    // add it to the behavioral subject which then adds it to the observable
    this.resultNew.next(resultMath);
  }
}
