import { Component, OnInit, Injectable, ViewEncapsulation } from '@angular/core';

import { AuthService } from '../../core/auth.service';
import { AngularFireAuth } from 'angularfire2/auth';

// get our interfaces
import { bankInformation } from '../../interfaces';

import {NgForm} from '@angular/forms';

// import the database stuff
import {
  AngularFirestore,
  AngularFirestoreDocument,
  AngularFirestoreCollection
  } from 'angularfire2/firestore';

import { FirestoreService } from '../../firestore.service';

// need observables
import { Observable } from 'rxjs/Observable';

import { DataService } from '../shared/data.service';

@Component({
  selector: 'app-calculator',
  templateUrl: './calculator.component.html',
  styleUrls: ['./calculator.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class CalculatorComponent implements OnInit {

  constructor(
    public auth: AuthService,
    public afAuth: AngularFireAuth,
    public afs: AngularFirestore,
    public db: FirestoreService,
    private dataService: DataService) {}


    sendBankInfo(userForm: NgForm) {
      this.dataService.getBankInfo(userForm.value);
    }

    resetBankForm(userForm: NgForm) {
      userForm.resetForm();
      window.scrollTo(0, 0);
    }

  ngOnInit() {

  }


}
