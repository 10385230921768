import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AngularFirestore } from 'angularfire2/firestore';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  title = 'Docutech\'s RIO Calulator';

  
  constructor(
    db: AngularFirestore,
    private router: Router
  ) {}

  

  ngOnInit() {
    this.router.events.subscribe((evt) => {

      if (!(evt instanceof NavigationEnd)) {
            return;
      }
        var scrollToTop = window.setInterval(function() {
            var pos = window.pageYOffset;
            if (pos > 0) {
                window.scrollTo(0, pos - 20); // how far to scroll on each step
            } else {
                window.clearInterval(scrollToTop);
            }
        }, 16); // how fast to scroll (this equals roughly 60 fps)
    });

  }
}
