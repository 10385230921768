import { Component, OnInit } from '@angular/core';
import { DataService } from '../../shared/data.service';
import { Observable } from 'rxjs/Observable';

@Component({
  selector: 'app-warehouse-results',
  templateUrl: './warehouse-results.component.html',
  styleUrls: ['./warehouse-results.component.scss']
})
export class WarehouseResultsComponent implements OnInit {

  eCloseType: boolean;
  WarehouseType: boolean;
  loanType: boolean;

  baseIndex: string;

  c12: number; // annual loan volume
  c13: number; // annual loan size
  c47: number;
  c47percent: number;
  c48: number;
  c49: number;
  c49percent: number;
  c50: number;
  c51: number; // excess wh line
  c52: number; // wh line liquidy
  c54: number; // paper days in warehouse
  c55: number; // eclose days in warehouse
  c57percent: number;
  c57: number; // coupon
  c58: number;
  c59: number;

  resultMortAnnLoanVol: number = 0;
  resultWarehouseCOF: number = 0;
  resultSpreadDiff: number = 0;
  resultSpreadPaper: number = 0;
  resultSpreadFull: number = 0;
  resultInventPerMonthPaper: number = 0;
  resultInventPerMonthEClose: number = 0;
  resultLineSupportFundingLevelPaper: number = 0;
  resultLineSupportFundingLevelEClose: number = 0;
  resultOriginationFeePaper: number = 0;
  resultOriginationFeeEClose: number = 0;
  resultTotalHaircutPaper: number = 0;
  resultTotalHaircutEClose: number = 0;
  resultLiqReqPaper: number = 0;
  resultLiqReqEClose: number = 0;
  resultLiqCashReqPaper: number = 0;
  resultLiqCashReqEClose: number = 0;
  resultCostCapPaper: number = 0;
  resultCostCapEClose: number = 0;
  resultCostLoanPaper: number = 0;
  resultCostLoanEClose: number = 0;

  constructor(private dataService:DataService) { }

  ngOnInit() {

    this.dataService.broadcastECloseType.subscribe((data) => {
      this.eCloseType = data;
      // console.log('eclose type');
      // console.log(this.eCloseType);
    });

    this.dataService.broadcastLoanType.subscribe((data) => {
      this.loanType = data;
      // console.log('loan type');
      // console.log(this.eCloseType);
    });

    this.dataService.broadcastWarehouse.subscribe((data) => {
        this.WarehouseType = data;
        // console.log('warehouse type');
        // console.log(this.WarehouseType);
    });

    this.dataService.broadcastResults.subscribe(

      data => {
        if (data === '') {
          console.log('empty');
        } else {
          const theKey = data[0];
          // console.log('the data is here');

          this.eCloseType = theKey['togFullHybrid'];
          this.loanType = theKey['togMortHomeEq'];

          this.baseIndex = theKey['warehouseIndex'];

          // CALC INPUTS
          this.c12 = theKey['annualLoanVolume'];
          this.c13 = theKey['annualLoanSize'];
          // base rate
          this.c47percent = theKey['warehouseBaseRate'];
          this.c47 = Number(theKey['warehouseBaseRate'] / 100);

          // margin over base
          this.c48 = theKey['warehouseMarginOverBase'];

          this.c49 = Number(theKey['warehouseOrigination']);
          this.c49percent = Number(theKey['warehouseOrigination'])*100;

          // wh haircut
          this.c50 = Number(theKey['warehouseHaircut']);
          // excess wh line
          this.c51 = Number(theKey['warehouseExcess'] / 100);
          this.c52 = Number(theKey['warehouseLineLiq'] / 100);
          // wh line liquidy
          this.c54 = theKey['warehousePaperClose'];
          // paper days in warehouse
          this.c55 = theKey['warehouseEClose'];
          this.c57percent = Number(theKey['warehouseNoteRate']) * 100;
          this.c57 = Number(theKey['warehouseNoteRate']);

          this.c58 = Number(theKey['warehouseEarlyDelivPrem']);         // early delivery
          this.c59 = Number(theKey['warehousePercentEarlyDelivPrem']);  // % product early deliver

        }

      // **************
      // RESULTS
      // **************

      this.resultMortAnnLoanVol = (this.c12 * this.c13) / 12;

      this.resultWarehouseCOF = (this.c47percent + (this.c48 / 100));

      this.resultSpreadDiff = (this.c57percent - this.resultWarehouseCOF);

      this.resultSpreadPaper = ((
        this.c13 * this.c54 / 365 * this.c57) -
        (this.c13 * (1 - this.c50) * this.c54 / 365 * (this.c47 + (this.c48 / 10000))));

      this.resultSpreadFull = ((this.c13 * this.c55 / 365 * this.c57) - (this.c13 * (1 - this.c50) * this.c55 / 365 * (this.c47 + (this.c48 / 10000))));

      this.resultInventPerMonthPaper = (365 / 12) / this.c54;

      this.resultInventPerMonthEClose = (365 / 12) / this.c55;

      this.resultLineSupportFundingLevelPaper = Math.round((
        this.resultMortAnnLoanVol / this.resultInventPerMonthPaper) *
      (1 + this.c51) * 1000) / 1000;

      this.resultLineSupportFundingLevelEClose = Math.round((this.resultMortAnnLoanVol / this.resultInventPerMonthEClose) *
      (1 + this.c51) * 1000) / 1000;

      this.resultOriginationFeePaper = Math.round(this.resultLineSupportFundingLevelPaper * this.c49 * 1000) / 1000;

      this.resultOriginationFeeEClose = Math.round(this.resultLineSupportFundingLevelEClose * this.c49 * 1000) / 1000;

      this.resultTotalHaircutPaper = Math.round(this.resultLineSupportFundingLevelPaper * this.c50 / 1000) * 1000;

      this.resultTotalHaircutEClose = Math.round(this.resultLineSupportFundingLevelEClose * this.c50 / 1000) * 1000;

      this.resultLiqReqPaper = Math.round(this.resultLineSupportFundingLevelPaper * this.c52 / 1000) * 1000;

      this.resultLiqReqEClose = Math.round(this.resultLineSupportFundingLevelEClose * this.c52 / 1000) * 1000;

      this.resultLiqCashReqPaper = this.resultTotalHaircutPaper + this.resultLiqReqPaper;

      this.resultLiqCashReqEClose = this.resultTotalHaircutEClose +  this.resultLiqReqEClose;


      this.resultCostCapPaper = Math.round(this.resultLiqCashReqPaper * .15 / 1000) * 1000;
      this.resultCostCapEClose = Math.round(this.resultLiqCashReqEClose * .15 / 1000) * 1000;
      this.resultCostLoanPaper = this.resultCostCapPaper / this.c12;
      this.resultCostLoanEClose = this.resultCostCapEClose / this.c12;;
      }
    );
  }
}
