import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../core/auth.service';
import {ActivatedRoute} from '@angular/router';
import {
  ReactiveFormsModule,
  FormGroup,
  FormBuilder,
  Validators
} from '@angular/forms';

@Component({
  selector: 'register-form',
  templateUrl: './register-form.component.html',
  styleUrls: ['./register-form.component.scss']
})

export class registerFormComponent implements OnInit {

  registerForm: FormGroup;
  newUser = true; // to toggle login or signup form

  constructor(
    private fb: FormBuilder,
    private auth: AuthService,
    private route: ActivatedRoute) {


  }

  formErrors = {
    'email': '',
    'password': ''
  };

  validationMessages = {
    'email': {
      'required':      'Email is required.',
      'email':         'Email must be a valid email'
    },
    'password': {
      'required':      'Password is required.',
      'pattern':       'Password must be include at one letter and one number.',
      'minlength':     'Password must be at least 4 characters long.',
      'maxlength':     'Password cannot be more than 40 characters long.',
    }
  };


  ngOnInit(): void {
     this.buildForm();
   }

   signUp(): void {
     this.auth.registerUser(this.registerForm.value['email'], this.registerForm.value['password']);
   }


   /*login(): void {
     this.auth.emailLogin(this.registerForm.value['email'], this.registerForm.value['password'])
   }

   resetPassword() {
     this.auth.resetPassword(this.registerForm.value['email'])
     .then(() => this.passReset = true)
   }*/


   buildForm(): void {
     this.registerForm = this.fb.group({
      'email': ['', [
           Validators.required,
           Validators.email
         ]
       ],
       'password': ['', [
          Validators.pattern('^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9!@#\$%\^&\*]+)$'),
          Validators.minLength(6),
          Validators.maxLength(25)
       ]
     ],
     });

     this.registerForm.valueChanges.subscribe(data => this.onValueChanged(data));
     this.onValueChanged(); // reset validation messages
   }

   // Updates validation state on form changes.
  onValueChanged(data?: any) {
    if (!this.registerForm) { return; }
    const form = this.registerForm;
    for (const field in this.formErrors) {

      if (this.formErrors.hasOwnProperty(field)) {
        // clear previous error message (if any)
        this.formErrors[field] = '';
        const control = form.get(field);
        if (control && control.dirty && !control.valid) {
          const messages = this.validationMessages[field];
          for (const key in control.errors) {
            if (control.errors.hasOwnProperty(field)) {
              this.formErrors[field] += messages[key] + ' ';
            }
          }
        }
      }
    }
  }


}
