import { Component, OnInit, Injectable } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { AuthService } from '../../core/auth.service';
import { AngularFireAuth } from 'angularfire2/auth';
import { Observable } from 'rxjs';

@Injectable()

@Component({
  selector: 'app-top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.scss'],
  animations: [
  trigger('collapse', [
    state('open', style({
      opacity: '1',
      display: 'block',
      transform: 'translate3d(0, 0, 0)'
    })),
    state('closed',   style({
      opacity: '0',
      display: 'none',
      transform: 'translate3d(0, -100%, 0)'
    })),
    transition('closed => open', animate('200ms ease-in')),
    transition('open => closed', animate('100ms ease-out'))
  ])
]
})



export class TopNavComponent implements OnInit {

  // collapse:string = "closed";
  show = false;
  authState  = null;

  constructor(
    public auth: AuthService,
    public afAuth: AngularFireAuth
    ) {

    this.afAuth.authState.subscribe(() => {
        this.authState = auth;
        // console.log('authorization on top nav');
        if (this.authState) {
          // console.log('auth top nav');
          // console.log(this.authState.email);
          this.show = true;
        }
      });

  }


  ngOnInit() {
    // console.log('my authState');
    // console.log(this.afAuth.authState);
  }

  logout() {
    this.auth.signOut();
  }

  toggleCollapse() {
    this.show = !this.show;
    // this.collapse = this.collapse == "open" ? 'closed' : 'open';

  }

}
