import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../core/auth.service';

import { ReactiveFormsModule, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { User, SendEmail } from '../../interfaces';
import { AngularFireAuth } from 'angularfire2/auth';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from 'angularfire2/firestore';
import { Observable } from 'rxjs/Observable';
import { saveAs } from 'file-saver';
import swal from 'sweetalert2';

// import { ObjectsToCsv } from 'objects-to-csv';

 declare var require: any;
 var firebase = require("firebase/app");

 const objectData = [];


@Component({
  selector: 'user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})

export class UserProfileComponent implements OnInit {

  reportsRef: AngularFirestoreCollection<any>;
  reports: Observable<any>;

  curEmail: string;

  userCollectionRef: AngularFirestoreCollection<User>;

  sendEmailsCollectionRef: AngularFirestoreCollection<SendEmail>;

  success = false;
  tryAgain = false;

  constructor(
    public auth: AuthService,
    public afAuth: AngularFireAuth,
    private fb: FormBuilder,
    private afs: AngularFirestore) {
    this.userCollectionRef = this.afs.collection<User>('Users');
    this.sendEmailsCollectionRef = this.afs.collection<SendEmail>('SendEmails');
  }

  updateAccount: FormGroup;

    ngOnInit() {
      this.buildUpdateAccountForm();
      // this.getUserInfo();
      this.getObjectArray();
      this.reportsRef = this.afs.collection('SendEmails');

      // Map the snapshot to include the document ID
      this.reports = this.reportsRef
        .snapshotChanges().map(arr => {
          return arr.map(snap => {
          const data = snap.payload.doc.data()
          const id = snap.payload.doc.id
          return { ...data, id }
        });
      });
  }

  requestReport() {
    const data = {
      status: 'processing',
      createdAt: new Date()
    };
    this.reportsRef.add(data);
  }

  buildUpdateAccountForm(): void {
    this.updateAccount = this.fb.group({
      'accountEmail': [this.curEmail, [] ],
    });
  }


updateAccountInfo() {
    const newEmail = this.updateAccount.value['accountEmail'];

    const userData: User = {
      // email: newEmail
      //authenticated: true,
      //displayName: '',
      email: newEmail,
      //firstName: '',
      //roles: {admin: true, editor: true, subscriber: true, superAdmin: true},
      //uid: ''
    };

    // this.userCollectionRef.doc('john@thinkbigfish.com').set(userData)
    //   .then( ()=>{
    //     console.log('set userData is successful');
    //     // this.getRates();
    //     console.log("HERE", this.userCollectionRef.doc('john@thinkbigfish.com'))
    //   })
    //   .catch( error => {
    //     console.log('error on sending userData');
    //     console.log(error);
    //   });

  //   var user = firebase.auth().currentUser;

  //   console.log("da user", user)

    // user.updateProfile({
    // 	  firstName: "Joe",
    // 	}).then(function() {
    // 	  console.log("it worked")
    // 	}).catch(function(error) {
    // 	  console.log("it did not")
    // });

}


// Function to reset password
resetPassword() {

  const authPassw = firebase.auth();
  const emailAddress = '' + this.afAuth.auth.currentUser.email + '';
  console.log(emailAddress);

  const that = this;
  document.querySelector('.create--spinner .icon .fas').classList.add('fa-spinner');
  document.querySelector('.create--spinner .icon .fas').classList.add('fa-spin');

  return this.auth.resetPassword(emailAddress).then(function() {
    // Email sent.
    console.log('email sent');
    document.querySelector('.create--spinner .icon .fas').classList.remove('fa-spinner');
    document.querySelector('.create--spinner .icon .fas').classList.remove('fa-spin');
    swal({
      title: 'Success!',
      text: 'A password reset has been sent to ' + emailAddress + '. Please follow the instructions in your email.',
      type: 'success',
      confirmButtonText: 'OK',
      confirmButtonColor: '#85dce6',
      confirmButtonClass: 'button is-primary is-uppercase',
      buttonsStyling: false
    });
    that.success = true;
    that.tryAgain = false;

  }).catch(function(error) {
    // An error happened.
    console.log('email did not')
    swal({
      title: 'Error!',
      text: 'There was an error in trying to send the password reset. Please try again.',
      type: 'error',
      confirmButtonText: 'OK',
      confirmButtonColor: '#85dce6',
      confirmButtonClass: 'button is-primary is-uppercase',
      buttonsStyling: false
    });
    that.success = false;
    that.tryAgain = true;
  });
}

  private getObjectArray(){
    let object;

    this.afs.collection('SendEmails').ref.get().then((snapshot) => {
      snapshot.docs.forEach(doc => {
        objectData.push(doc.data());
        console.log(objectData);
      });
    });
  }

  //Objects-to-csv method

//   private downloadCSV(){
//     (async() =>{
//       let csv = new ObjectsToCsv(objectData);

//       // Save to file:
//       await csv.toDisk('./test.csv');

//       // Return the CSV file as string:
//       console.log(await csv.toString());
//     })();
//   }
// }

  downloadCSV(data: any) {
      console.log('here tis', data);
      console.log('and dis', objectData);
      const replacer = (key: any, value: any) => value === null ? '' : value; // specify how you want to handle null values here
      const header = Object.keys(objectData[0]);
      const csv = objectData.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
      csv.unshift(header.join(','));
      const csvArray = csv.join('\r\n');

      const blob = new Blob([csvArray], {type: 'text/csv' });
      saveAs(blob, 'calculatorReport.csv');

  }
}
