import { Component, OnInit, Input } from '@angular/core';
import * as firebase from 'firebase';
import { Observable } from 'rxjs/Observable';
import { from } from 'rxjs';
@Component({
  selector: 'app-report-link',
  templateUrl: './report-link.component.html',
  styleUrls: ['./report-link.component.scss']
})
export class ReportLinkComponent implements OnInit {

  @Input() report: any;
  downloadUrl: Observable<string>;

  constructor() { }

  ngOnInit() {
    const storageRef = firebase.storage().ref(`SendEmails/${this.report.id}`);
    const promise = storageRef.getDownloadURL();
    this.downloadUrl = from(promise);
  }

}
