import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { LoadingSpinnerComponent } from '../ui/loading-spinner/loading-spinner.component';

// CALCULATOR
import { CalculatorDashboardComponent } from '../calculator/calculator-dashboard/calculator-dashboard.component';
import { CalculatorComponent } from '../calculator/calculator-display/calculator.component';
import { CalculatorFormComponent } from '../calculator/calculator-form/calculator-form.component';
import { CalculatorWarehouseComponent } from '../calculator/calculator-warehouse/calculator-warehouse.component';
import { CalculatorResultsComponent } from '../calculator/calculator-results/calculator-results.component';
import { HomeEquityResultsComponent } from '../calculator/calculator-results/home-equity-results/home-equity-results.component';
import { MortgageResultsComponent } from '../calculator/calculator-results/mortgage-results/mortgage-results.component';
import { WarehouseResultsComponent } from '../calculator/calculator-results/warehouse-results/warehouse-results.component';

// FILTERS AND KEYS
import { KeysPipe } from '../pipes/keys';
import { FilterPipe} from '../pipes/filter.pipe';
import { CapitalizePipe } from '../pipes/capitalize.pipe';
import { MinusSignToParens } from '../pipes/minusToParenthesis.pipe';

import { TopNavComponent } from '../ui/top-nav/top-nav.component';
import { FooterNavComponent } from '../ui/footer-nav/footer-nav.component';

// TABS
import { Tabs } from '../ui/tabs/tabs.component';
import { Tab } from '../ui/tab/tab.component';

// AVATAR
import { AvatarModule } from 'ngx-avatar';

import { ArchwizardModule } from 'angular-archwizard';

const avatarColors = [
  '#FFB6C1',
  '#2c3e50',
  '#95a5a6',
  '#f39c12',
  '#1abc9c'
];


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        AvatarModule.forRoot({
          colors: avatarColors
        }),
        ArchwizardModule
    ],
    declarations: [
        LoadingSpinnerComponent,
        //  CALCULATOR
        CalculatorComponent,
        CalculatorDashboardComponent,
        CalculatorFormComponent,
        CalculatorResultsComponent,
        CalculatorWarehouseComponent,
        HomeEquityResultsComponent,
        MortgageResultsComponent,
        WarehouseResultsComponent,
        //  PIPES
        KeysPipe,
        FilterPipe,
        CapitalizePipe,
        MinusSignToParens,
        //  TABS
        Tab,
        Tabs,
        TopNavComponent,
        FooterNavComponent
    ],
    exports: [
        LoadingSpinnerComponent,
        Tabs,
        Tab,
        CalculatorComponent,
        CalculatorDashboardComponent,
        CalculatorFormComponent,
        CalculatorResultsComponent,
        CalculatorWarehouseComponent,
        HomeEquityResultsComponent,
        MortgageResultsComponent,
        TopNavComponent,
        FooterNavComponent
    ]
})
export class SharedModule {}
