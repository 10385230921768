import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})

export class FilterPipe implements PipeTransform {
   /* transform(items: any[], searchText: string): any[] {
        if(!items) return [];
        if(!searchText) return items;
        searchText = searchText.toLowerCase();
        return items.filter( it => {
            return it.toLowerCase().includes(searchText);
        });
    }*/
    // https://stackoverflow.com/questions/41378252/angular2-error-while-using-a-text-filter-pipe-to-filter-an-array
    transform(value: any, term: any) {
        if (!term) {
          return value;
        }
        return value.filter((item: any) => {
          for (const prop in item) {

            if (typeof item[prop] === 'string' && item[prop].indexOf(term) > -1) {
              return true;
            }
          }
          return false;
        });
      }
}
