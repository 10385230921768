import { Component, OnInit, Input } from '@angular/core';

import { Tabs } from '../tabs/tabs.component';

@Component({
  selector: 'tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss']
})
export class Tab {

  @Input() tabTitle: string;
  @Input() tabIcon: string;

  active: boolean;

  constructor(tabs:Tabs) {
    tabs.addTab(this);
  }

}
