import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as firebase from 'firebase';
import { AngularFireAuth } from 'angularfire2/auth';
import { AngularFirestore, AngularFirestoreDocument } from 'angularfire2/firestore';

import { User } from '../interfaces';

import { Observable, of } from 'rxjs';
import { switchMap, tap, startWith } from 'rxjs/operators';

// import the registration invite code email
// import { Http, Headers, Response, URLSearchParams } from '@angular/http';
// import { HttpClient, HttpHeaders, HttpParams, HttpClientModule } from "@angular/common/http";
// deprecated
import 'rxjs/add/operator/toPromise';

import { FirestoreService } from '../firestore.service';

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import swal from 'sweetalert2';
@Injectable()

export class AuthService {

  user: Observable<User>;

  constructor(private afAuth: AngularFireAuth,
              private afs: AngularFirestore,
              public db: FirestoreService,
              private router: Router,
              private http: HttpClient) {

        this.user = this.afAuth.authState.pipe(
          switchMap( user => {
            if (user) {
              console.log(user.uid);
              return this.afs.doc<User>(`Users/${user.email}`).valueChanges();
            } else {
              console.log('error of null');
              return of(null);
            }
          }),

          // Add these lines to set/read the user data to local storage
          // tap(user => localStorage.setItem('user', JSON.stringify(user))),
          // startWith(JSON.parse(localStorage.getItem('user')))
        );


  }

///// Role-based Authorization //////
canRead(user: User): boolean {
  const allowed = ['superAdmin', 'admin', 'editor', 'subscriber'];
  return this.checkAuthorization(user, allowed);
}
canViewBilling(user:User): boolean {
  const allowed = ['superAdmin', 'admin'];
  return this.checkAuthorization(user, allowed);
}

canCreateUsers(user: User): boolean {
  const allowed = ['superAdmin', 'admin'];
  return this.checkAuthorization(user, allowed);
}

canCreate(user: User): boolean {
  const allowed = ['superAdmin', 'admin', 'editor'];
  return this.checkAuthorization(user, allowed);
}
canUpdate(user: User): boolean {
  const allowed = ['superAdmin', 'admin', 'editor'];
  return this.checkAuthorization(user, allowed);
}
canEdit(user: User): boolean {
  const allowed = ['superAdmin', 'admin', 'editor'];
  return this.checkAuthorization(user, allowed);
}
canDelete(user: User): boolean {
  const allowed = ['superAdmin', 'admin'];
  return this.checkAuthorization(user, allowed);
}

isSuperAdmin(user: User): boolean {
  const allowed = ['superAdmin'];
  return this.checkAuthorization(user, allowed);
}
// determines if user has matching role
private checkAuthorization(user: User, allowedRoles: string[]): boolean {
  if (!user) {
    return false;
  }
  for (const role of allowedRoles) {
    if ( user.roles[role] ) {
      return true;
    }
  }
  console.log('checkAuthorization return false');
  return false;
}



/*
sendRegistrationLink(userEmail, generatedInvite) {
  console.log('got the registration function', generatedInvite);
    let url = '/register';
    let params = {
      'to': userEmail,
      'from': 'Docutech Portal',
      'reply': 'portal@docutech.com',
      'subject': 'You\'ve been invited to the Docutech Portal',
      'content': '<h1>Welcome to the Docutech Portal</h1>'+
      '<p>Use the link below with your invite code to register.</p>'+
      '<p>Your invite code is: ' + generatedInvite +
      '<p><a href="ADD_URL_HERE/register?inviteCode='+generatedInvite+'&email='+userEmail+'">Register</a></p>'
    }

    let headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    }

   console.log('headers');
   console.log(headers);
   console.log('params');
   console.log(params);

      return this.http
      .post(url, {params}, {headers})
      .subscribe(
        res => {
          console.log('res');
          console.log(res);
        },
        (err) => {
          console.log('error');
          console.log(err.error);
          console.log(err.name);
          console.log(err.message);
          console.log(err.status);
        }

      );

}
*/




  //
  // LOG USER IN
  //
  public signIn(email, password): any {
    console.log('running signIn');
    document.querySelector('.create--spinner .icon .fas').classList.add('fa-spinner');
    document.querySelector('.create--spinner .icon .fas').classList.add('fa-spin');
    return this.afAuth.auth.signInWithEmailAndPassword(email, password)
      .then(credential => {
        console.log('signin with email');
        console.log(this.afAuth.auth.currentUser.email);

        document.querySelector('.create--spinner .icon .fas').classList.remove('fa-spinner');
        document.querySelector('.create--spinner .icon .fas').classList.remove('fa-spin');
        const show = true;
        // this.router.navigate(['/calculator/create-roi-calculation']);
        this.router.navigate(['/dashboard']);
      }).catch((error) => {
        const errorCode = error['code'];
        const errorMessage = error['message'];

        if (errorCode === 'auth/wrong-password') {
          alert('It looks like your username or password are incorrect');
        } else if (errorCode === 'auth/user-not-found') {
          alert('It looks like this user doesn\'t exist');
        } else {
          alert(error['errorMessage']);
        }
        console.log('error message');
        console.log(errorMessage);
      });

  }



  get timestamp() {
    return firebase.firestore.FieldValue.serverTimestamp();
  }

  private updateAuthenticatedOnRegister(email, uid) {
    // Sets user data to firestore on login
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`Users/${email}`);

    const data: User = {
      authenticated: true,
      displayName: '',
      email: email,
      firstName: '',
      inviteCode: '',
      lastName: '',
      roles: {
        subscriber: true,
        editor: false,
        admin: false,
        superAdmin: false
      },
      uid: uid,
      userPhone: '',
      userSince: this.timestamp,

    };

    return userRef.set(data, { merge: true })

  }

  redirectToCalc() {
    return this.router.navigate(['/calculator/create-roi-calculation']);
  }
  //
  // REGISTER THE NEW USER
  //

  registerUser(email, password): any {

    document.querySelector('.create--spinner .icon .fas').classList.add('fa-spinner');
    document.querySelector('.create--spinner .icon .fas').classList.add('fa-spin');

    // create the user
    return this.afAuth.auth.createUserWithEmailAndPassword(email, password)
      .then( (user) => {
        console.log('registerUser');
        // console.log(user.user.uid);
        // const userID = user.user.uid;

        document.querySelector('.create--spinner .icon .fas').classList.remove('fa-spinner');
        document.querySelector('.create--spinner .icon .fas').classList.remove('fa-spin');

        // update the user authorized to true
        // come back to figure out why the user email and uid is not here
        this.updateAuthenticatedOnRegister(email, this.afAuth.auth.currentUser.uid);

      })
      .then( () => {
        console.log('the registration went through');
        return swal({
          title: 'Success!',
          text: 'Your are now registered.',
          type: 'success',
          confirmButtonText: 'OK',
          confirmButtonColor: '#85dce6',
          confirmButtonClass: 'button is-primary is-uppercase',
          buttonsStyling: false,

        });
      })
      .then( () => {
        return this.router.navigate(['/calculator/create-roi-calculation']);

      })
      .catch( (error) => {
        const errorCode = error['code'];
        const errorMessage = error['message'];

        if (errorCode === 'auth/wrong-password') {
          // alert('It looks like your username or password are incorrect');
          return swal({
            title: 'Error!',
            text: 'It looks like your username or invite code are incorrect. Please review your invite email for the correct credentials.',
            type: 'error',
            confirmButtonText: 'OK',
            confirmButtonColor: '#85dce6',
            confirmButtonClass: 'button is-primary is-uppercase',
            buttonsStyling: false,

          });
        } else if (errorCode === 'auth/user-not-found') {
          // alert('It looks like this user doesn\'t exist');
          return swal({
            title: 'Error!',
            text: 'It looks like this user does not exist. Please try again. If this persists, lease contact Docutech support.',
            type: 'error',
            confirmButtonText: 'OK',
            confirmButtonColor: '#85dce6',
            confirmButtonClass: 'button is-primary is-uppercase',
            buttonsStyling: false,

          });
        } else {
          // alert(error['errorMessage']);
        }
      // console.log(errorMessage);
      // this.handleSignUpError(errorMessage);
    });

  // end of createAuthUser
  }


  //
  // LOG USER OUT
  //
  public signOut() {
    this.afAuth.auth.signOut().then(() => {
      console.log('user is signed out');
      this.router.navigate(['/']);
    });
  }

  //
  // SEND THE USER AN INVITE EMAIL
  // WILL INCLUDE INVITE CODE AND LINK TO THE REGISTRATION
  //
  public sendRegistrationLink(email, code) {
    console.log('we are in the auth sendRegistrationLink')
    const inviteUser = firebase.functions().httpsCallable('inviteUserSend');
    return inviteUser({
        email: email,
        code: code
    })
    .then((result) => {
        console.log('Cloud Function for sending invite called successfully.', result);
        console.log(result);
        return result;


    })
    .catch((error) => {
        // Getting the Error details.
        console.log('There was an error when calling the Cloud Function', error);
        console.log(error.code);
        console.log(error.message);
        console.log(error.details);
        return error;
    });
  }

  resetPassword(email: string) {
    return this.afAuth.auth.sendPasswordResetEmail(email)
      .then(() => console.log('sent Password Reset Email!'))
      .catch((error) => console.log(error));
  }

//that the end of the export class AuthService
}
