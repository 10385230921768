import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../core/auth.service';
import { ReactiveFormsModule, FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'account-update-form',
  templateUrl: './account-update-form.component.html',
  styleUrls: ['./account-update-form.component.scss']
})

export class AccountUpdateFormComponent implements OnInit {

  accountUpdateForm: FormGroup;
  passReset = false; // set to true when password reset is triggered
  updateEmail = false;
  subscriber = false;

  formErrors = {
    'email': ''
  };

  validationMessages = {
    'email': {
      'required':      'Email is required.',
      'email':         'Email must be a valid email'
    }
  };

  constructor(private fb: FormBuilder, private auth: AuthService) {}

   ngOnInit(): void {
     this.buildForm();
   }

   updateUserEmail() {
     /*if( this.accountUpdateForm.value['subscriber'] != '' ){
       this.subscriber = true
     }
     this.auth.updateEmail(this.accountUpdateForm.value['email'], this.subscriber)
     */
    }

   buildForm(): void {
     this.accountUpdateForm = this.fb.group({
       'email': ['', [
           Validators.required,
           Validators.email
         ]
       ],
       'subscriber': ['checked', [
         ]
       ]
     });

     this.accountUpdateForm.valueChanges.subscribe(data => this.onValueChanged(data));
     this.onValueChanged(); // reset validation messages
   }

   // Updates validation state on form changes.
   onValueChanged(data?: any) {
     if (!this.accountUpdateForm) { return; }
     const form = this.accountUpdateForm;
     for (const field in this.formErrors) {
      if (this.formErrors.hasOwnProperty(field)) {
        // clear previous error message (if any)
        this.formErrors[field] = '';
        const control = form.get(field);
        if (control && control.dirty && !control.valid) {
          const messages = this.validationMessages[field];
          for (const key in control.errors) {
            if (control.errors.hasOwnProperty(field)) {
              this.formErrors[field] += messages[key] + ' ';
            }
          }
        }
      }
     }
   }



}
